export const APP_ENV = process.env.REACT_APP_ENV as string

export const ROOT_API = process.env.REACT_APP_API as string
export const ROOT_API_TIMEOUT = process.env.REACT_APP_API_TIMEOUT as string

export const TOKEN_TON = process.env.REACT_APP_TOKEN_TON as string

/** Cache key */
export const CACHE_TOKEN = 'auth'
export const CACHE_USER_DATA = 'user_data'
export const NetworkContextName = 'NETWORK'

// encrypt key
export const ENCRYPT_METHOD = process.env.REACT_APP_ENCRYPT_METHOD as string
export const ENCRYPT_SECRET_KEY = process.env.REACT_APP_ENCRYPT_SECRET_KEY as string

export const MIN_TON_FEE = 0.05
