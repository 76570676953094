import styled from 'styled-components'
import Tabs from './Tabs'
import { title } from 'process'
import classNames from 'classnames'
import { useState } from 'react'

const HERO_TYPES = [
  {
    key: 'archer',
    name: 'Archer',
    weapon: '/images/landing/hero/weapon-acher.png',
    avatar: '/images/landing/hero/avatar-acher.png',
    information: [
      {
        key: 'force',
        title: 'Force',
        value: 2,
      },
      {
        key: 'agility',
        title: 'Agility',
        value: 3,
      },
      {
        key: 'endurance',
        title: 'Endurance',
        value: 2,
      },
      {
        key: 'intelligence',
        title: 'Intelligence',
        value: 9,
      },
    ],
    description:
      'Ciri was born in Skellige in Belleteyn of the following year and initially, Calantheordered her advisor Mousesack to kill Geralt, wishing to break free from the Lawof Surprise. Mousesack was ready to go, as nobody refusedthe queen, but when he was summoned a short time later Calanthe retracted the order without explanation.kill Geralt, wishing to break free from the Lawof Surprise. Mousesack was ready to go, as nobody refusedthe queen, ',
  },
  {
    key: 'assassin',
    name: 'Assassin',
    weapon: '/images/landing/hero/weapon-assassin.png',
    avatar: '/images/landing/hero/avatar-assassin.png',
    information: [
      {
        key: 'force',
        title: 'Force',
        value: 2,
      },
      {
        key: 'agility',
        title: 'Agility',
        value: 3,
      },
      {
        key: 'endurance',
        title: 'Endurance',
        value: 2,
      },
      {
        key: 'intelligence',
        title: 'Intelligence',
        value: 9,
      },
    ],
    description:
      'Ciri was born in Skellige in Belleteyn of the following year and initially, Calantheordered her advisor Mousesack to kill Geralt, wishing to break free from the Lawof Surprise. Mousesack was ready to go, as nobody refusedthe queen, but when he was summoned a short time later Calanthe retracted the order without explanation.kill Geralt, wishing to break free from the Lawof Surprise. Mousesack was ready to go, as nobody refusedthe queen, ',
  },
  {
    key: 'magician',
    name: 'Magician',
    weapon: '/images/landing/hero/weapon-magician.png',
    avatar: '/images/landing/hero/avatar-magician.png',
    information: [
      {
        key: 'force',
        title: 'Force',
        value: 2,
      },
      {
        key: 'agility',
        title: 'Agility',
        value: 3,
      },
      {
        key: 'endurance',
        title: 'Endurance',
        value: 2,
      },
      {
        key: 'intelligence',
        title: 'Intelligence',
        value: 9,
      },
    ],
    description:
      'Ciri was born in Skellige in Belleteyn of the following year and initially, Calantheordered her advisor Mousesack to kill Geralt, wishing to break free from the Lawof Surprise. Mousesack was ready to go, as nobody refusedthe queen, but when he was summoned a short time later Calanthe retracted the order without explanation.kill Geralt, wishing to break free from the Lawof Surprise. Mousesack was ready to go, as nobody refusedthe queen, ',
  },
  {
    key: 'warrior',
    name: 'Warrior',
    weapon: '/images/landing/hero/weapon-warrior.png',
    avatar: '/images/landing/hero/avatar-warrior.png',
    information: [
      {
        key: 'force',
        title: 'Force',
        value: 2,
      },
      {
        key: 'agility',
        title: 'Agility',
        value: 3,
      },
      {
        key: 'endurance',
        title: 'Endurance',
        value: 2,
      },
      {
        key: 'intelligence',
        title: 'Intelligence',
        value: 9,
      },
    ],
    description:
      'Ciri was born in Skellige in Belleteyn of the following year and initially, Calantheordered her advisor Mousesack to kill Geralt, wishing to break free from the Lawof Surprise. Mousesack was ready to go, as nobody refusedthe queen, but when he was summoned a short time later Calanthe retracted the order without explanation.kill Geralt, wishing to break free from the Lawof Surprise. Mousesack was ready to go, as nobody refusedthe queen, ',
  },
]

const Play: React.FC = () => {
  const [activeHero, setActiveHero] = useState(HERO_TYPES[0])

  const handlePlay = () => {
    window.open('https://t.me/Zeus88Bot_bot/claim', '_blank')
  }

  return (
    <Wrapper>
      <div className='container'>
        <div className='title-box'>
          <Tabs />
          <h1>Choose Your Hero</h1>
          <p>
            Do you want to rush into the thick of the battle or help your allies? Or maybe do a little bit of everything? In our
            game you will always find something to do
          </p>
        </div>
        <div className='content-box'>
          <div className='types'>
            {HERO_TYPES.map((hero) => (
              <div
                onClick={() => setActiveHero(hero)}
                className={classNames({
                  active: activeHero?.key === hero?.key,
                })}
                key={hero?.key}
              >
                <img src={hero?.weapon} />
              </div>
            ))}
          </div>
          <div className='detail'>
            <div className='head'>
              <img src={activeHero?.avatar} alt={activeHero?.name} />
              <div className='information'>
                <h3>Hero Information</h3>
                <div className='info-bars'>
                  {activeHero?.information.map((info) => (
                    <div className='info-bar-item' key={info?.key}>
                      <div className='head'>
                        <span>{info?.title}</span>
                        <span>{info?.value * 10}</span>
                      </div>
                      <img src={`/images/landing/hero/info-bar-${info?.value}.png`} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <p className='description'>{activeHero?.description}</p>
          </div>
        </div>
        <button>
          <span onClick={handlePlay}>Play Game</span>
        </button>
      </div>
    </Wrapper>
  )
}

export default Play

const Wrapper = styled.div`
  padding: 63px 0;

  background-image: url('/images/landing/play-game-bg.webp');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  transform: translateY(-80px);

  border-bottom: 2px solid;
  border-image-slice: 1;
  border-width: 2px;
  border-image-source: linear-gradient(269.77deg, rgba(237, 189, 75, 0) 4.14%, #edbd4b 56.29%, rgba(237, 189, 75, 0) 95.64%);

  @media screen and (max-width: 1024px) {
    transform: translateY(-36px);
  }

  @media screen and (max-width: 768px) {
    transform: translateY(-24px);
  }

  @media screen and (max-width: 640px) {
    transform: translateY(-12px);
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 44px;

    .title-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 586px;

      h1 {
        font-size: 62px;
        font-weight: 700;
        line-height: 79.13px;
        text-align: center;
        background: linear-gradient(167.47deg, #fdfffd 33.85%, #fffa8e 65.47%, #fffb9f 72.42%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        word-spacing: -20px;
      }

      p {
        font-family: Lora;
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        text-align: center;
      }

      p,
      h1 {
        text-align: center;
      }
    }

    .content-box {
      aspect-ratio: 16/9;
      width: 100%;
      background-image: url('/images/landing/play-game-border.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% 100%;
      padding: 70px 58px;
      display: flex;
      align-items: center;
      gap: 58px;

      @media screen and (max-width: 768px) {
        background-image: none;
        flex-direction: column-reverse;
        background-color: #00000099;
        border: 1px solid #fffa8e;
        border-radius: 44px;
      }

      @media screen and (max-width: 640px) {
        border-radius: 24px;
        padding: 0;
        padding-bottom: 16px;
        gap: 24px;
      }

      .types {
        backdrop-filter: blur(24px);
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.04) 100%);
        padding: 16px;
        border-radius: 24px;
        gap: 32px;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media screen and (max-width: 768px) {
          flex-direction: row;
        }

        @media screen and (max-width: 640px) {
          width: calc(100% - 24px);
          overflow-x: auto;
          gap: 16px;
          margin-left: 16px;
          margin-right: 16px;
          justify-content: space-evenly;
        }

        > div {
          background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.16) 49.5%, rgba(0, 0, 0, 0) 100%),
            radial-gradient(50% 50% at 50% -25.63%, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0.05) 100%);
          box-shadow: 0px 0px 42px 0px #00000040;
          width: 80px;
          height: 80px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 21px;
          cursor: pointer;

          @media screen and (max-width: 640px) {
            flex-shrink: 0;
            width: 64px;
            height: 64px;
            border-radius: 16px;
          }

          &.active {
            background-image: url('/images/landing/active-weapon-bg.png');
            background-repeat: no-repeat;
            background-size: 100% 100%;
          }

          img {
            width: 64px;
            height: 64px;
            object-fit: contain;

            @media screen and (max-width: 640px) {
              width: 44px;
              height: 44px;
            }
          }
        }
      }

      .detail {
        flex-grow: 1;
        background: linear-gradient(122.85deg, rgba(28, 28, 28, 0.12) 1.31%, rgba(22, 22, 0, 0.44) 91.46%);
        border-bottom: 1px solid #666666;
        border-radius: 60px;
        backdrop-filter: blur(24px);
        padding: 32px;
        display: flex;
        flex-direction: column;
        gap: 24px;

        @media screen and (max-width: 640px) {
          padding: 24px;
          border-radius: 24px;
        }

        .head {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 52px;

          @media screen and (max-width: 768px) {
            grid-template-columns: 1fr;
          }

          @media screen and (max-width: 640px) {
            gap: 24px;
          }

          > img {
            width: 100%;
          }

          .information {
            width: 100%;
            display: flex;
            gap: 32px;
            flex-direction: column;

            @media screen and (max-width: 640px) {
              gap: 18px;
            }

            h3 {
              font-size: 28px;
              font-weight: 600;
              line-height: 35.84px;
              text-align: left;
              color: #ffffff;

              @media screen and (max-width: 640px) {
                font-size: 24px;
              }
            }

            .info-bars {
              display: flex;
              flex-direction: column;
              gap: 16px;

              .info-bar-item {
                display: flex;
                flex-direction: column;
                gap: 3px;

                .head {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;

                  > span {
                    padding: 0 24px;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 23.04px;
                    text-align: left;

                    @media screen and (max-width: 640px) {
                      font-size: 16px;
                    }
                  }
                }

                img {
                  width: 100%;
                  object-fit: contain;
                }
              }
            }
          }
        }

        .description {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          text-align: left;

          @media screen and (max-width: 640px) {
            font-size: 14px;
          }
        }
      }
    }

    button {
      cursor: pointer;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      border: none;
      background-color: transparent;
      padding: 22px 46px;
      color: #000;
      font-size: 26px;
      font-weight: 700;
      background-image: url('/images/landing/gold-btn.png');

      @media screen and (max-width: 1024px) {
        font-size: 16px;
      }
    }
  }
`
