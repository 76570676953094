import { DefaultTheme } from 'styled-components'
import base from './base'
import { darkColors } from './colors'
import { light as lightRadio } from 'widgets/Radio/theme'

const darkTheme: DefaultTheme = {
  ...base,
  isDark: true,
  colors: darkColors,
  radio: lightRadio,
}

export default darkTheme
