import { Spin } from 'antd'
import { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { checkUserData } from 'services/curl/Auth'
import useGameService from 'services/curl/Games'
import { setModalNotification } from 'state/modal/actions'
import styled from 'styled-components'
import Modal from './Modal'

const MissionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;

  .mission {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    border-radius: 5px;

    color: #8d4500;
    font-family: Jua;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    div {
      flex: 1;
    }

    img {
      width: 50px;
    }

    button {
      background-color: #81c11b;
      border: 1px solid #083f0b;
      border-bottom: 3px solid #083f0b;
      outline: none;
      border-radius: 0.5rem;
      padding: 0.3rem 0.5rem;

      color: #083f0b;
      font-family: Jua;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      display: flex;
      align-items: center;
      gap: 5px;

      :disabled {
        opacity: 0.7;
      }
    }
  }
`

interface MissionsProps {
  show: boolean
  onClose: () => void
}

const Missions: FC<MissionsProps> = ({ show, onClose }) => {
  const userData = checkUserData()
  const dispatch = useDispatch()
  const { fetchMissions, fetchUpdateMission } = useGameService()

  const [missionList, setMissionList] = useState<{ loading: boolean; data: null | any[] }>({
    loading: true,
    data: null,
  })
  const [loading, setLoading] = useState(false)

  const renderSvg = (name: string) => {
    if (name?.includes('Telegram'))
      return {
        logo: <img src='/images/icons/Telegram.png' alt='telegram' />,
        icon: (
          <svg xmlns='http://www.w3.org/2000/svg' width='12' viewBox='0 0 26 18' fill='none'>
            <path d='M3 9.73797L8.97668 14.4545L23.1713 3' stroke='#083F0B' strokeWidth='5' strokeLinecap='round' />
          </svg>
        ),
      }

    if (name?.includes('X'))
      return {
        logo: <img src='/images/icons/Twitter.png' alt='twitter' />,
        icon: (
          <svg xmlns='http://www.w3.org/2000/svg' width='12' viewBox='0 0 26 18' fill='none'>
            <path d='M3 9.73797L8.97668 14.4545L23.1713 3' stroke='#083F0B' strokeWidth='5' strokeLinecap='round' />
          </svg>
        ),
      }

    if (name?.includes('Discord'))
      return {
        logo: <img src='/images/icons/Discord.png' alt='discord' />,
        icon: (
          <svg xmlns='http://www.w3.org/2000/svg' width='12' viewBox='0 0 26 18' fill='none'>
            <path d='M3 9.73797L8.97668 14.4545L23.1713 3' stroke='#083F0B' strokeWidth='5' strokeLinecap='round' />
          </svg>
        ),
      }

    return {
      logo: <img src='/images/home/paw.png' alt='discord' />,
      icon: <></>,
    }
  }

  const handleConfirmMission = async (mission: any) => {
    if (typeof window !== 'undefined') {
      window.open(mission?.linkSocial)
    }

    const payload = {
      id: mission?.id,
      body: {
        isComplete: true,
      },
    }

    setLoading(true)

    await fetchUpdateMission(
      payload,
      async () => {
        setLoading(false)
        dispatch(
          setModalNotification({
            toggle: true,
            dataModal: {
              message: 'Mission Completed!!!',
            },
          })
        )
        await fetchMissions({ keyword: userData?.address }, (res) => {
          setMissionList({
            loading: false,
            data: res?.data?.data,
          })
        })
      },
      () => {
        setLoading(false)
        toast.error('Mission failed')
      }
    )
  }

  useEffect(() => {
    fetchMissions({ keyword: userData?.address }, (res) => {
      setMissionList({
        loading: false,
        data: res?.data?.data,
      })
    })
  }, [userData?.address, fetchMissions])

  return (
    <Modal show={show} onClose={onClose}>
      <div className={'modal-header small'}>
        <div>Complete missions</div>
        <div>to earn rewards</div>
      </div>
      <MissionsWrapper>
        {missionList?.loading ? (
          <></>
        ) : (
          missionList?.data?.map((item) => (
            <div className='mission' key={item?.id}>
              {renderSvg(item?.name).logo}
              <div>{item?.name}</div>
              {!item?.isComplete && (
                <button onClick={() => handleConfirmMission(item)} disabled={loading}>
                  {renderSvg(item?.name).icon}
                  <div>1 Gas-free {loading && <Spin size='small' />}</div>
                </button>
              )}
            </div>
          ))
        )}
      </MissionsWrapper>
    </Modal>
  )
}

export default Missions
