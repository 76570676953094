import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { setModalNotification } from 'state/modal/actions'
import styled from 'styled-components'
import { Box } from 'widgets'
import Friends from '../widgets/Poms/Friends'
import Missions from '../widgets/Poms/Missions'

export const Wrapper = styled.div`
  padding: 0 1rem 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const Dock = styled.div`
  border-radius: 1.5rem;
  background: rgba(217, 217, 217, 0.48);
  padding: 0.5rem 0.8rem;
  display: flex;
  justify-content: space-around;
  align-items: center;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    color: rgba(255, 255, 255, 0.5);

    &.active {
      color: #fff;
    }

    * {
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      font-family: Jua;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    img {
      width: 50px;
    }
  }
`

const PrivateLayoutWithDock = ({ children }) => {
  const dispatch = useDispatch()

  const [showMissionsModal, setShowMissionsModal] = useState(false)
  const [showFriendsModal, setShowFriendsModal] = useState(false)

  const handleComingSoon = () => {
    dispatch(
      setModalNotification({
        toggle: true,
        dataModal: {
          message: 'Coming Soon',
        },
      })
    )
  }

  return (
    <Wrapper>
      {children}
      <Dock>
        <Box
          onClick={() => {
            setShowMissionsModal(true)
          }}
          className={showMissionsModal ? 'active' : ''}
        >
          <img src='/images/home/paw.png' alt='mission' />
          <span>Mission</span>
        </Box>
        <Box
          onClick={() => {
            setShowFriendsModal(true)
          }}
          className={showFriendsModal ? 'active' : ''}
        >
          <img src='/images/home/friends.png' alt='friends' />
          <span>Friends</span>
        </Box>
        <Box onClick={handleComingSoon}>
          <img src='/images/home/port.png' alt='port' />
          <span>Ports</span>
        </Box>
      </Dock>
      <Missions show={showMissionsModal} onClose={() => setShowMissionsModal(false)} />
      <Friends show={showFriendsModal} onClose={() => setShowFriendsModal(false)} />
    </Wrapper>
  )
}

export default PrivateLayoutWithDock
