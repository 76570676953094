import { Layout } from 'antd'
import styled from 'styled-components'

const MyLayout = styled(Layout)`
    min-height: 100vh;
`
const Wrapper = styled.div`
    background-size: contain;
    background-position: top;
    background-repeat: no-repeat;
    overflow: hidden;
    position: relative;

    ${({ theme }) => theme.mediaQueries.xl} {
        background-size: cover;
    }

    .ant-layout {
        background: unset;
    }

    .container {
        width: 100%;
        position: relative;

        .let-kill-boss {
            position: relative;
            margin-top: 95px;
            display: block;

            img {
                margin: auto;
                display: block;

                &:nth-child(1) {
                    max-width: 700px;
                    width: 100%;
                    z-index: 10;
                    position: relative;
                    transform: translateX(2px);

                    ${({ theme }) => theme.mediaQueries.sm} {
                        width: 85%;
                    }

                    ${({ theme }) => theme.mediaQueries.lg} {
                        width: 43%;
                        transform: unset;
                    }
                }

                &:nth-child(2) {
                    position: absolute;
                    width: 100%;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    z-index: 0;
                    --translateX: -50%;
                    --translateY: -50%;
                    animation: haloScaleRotate 20s linear infinite;
                }
            }
        }
    }
`

const PublicLayout = ({ children }) => {
  return (
    <Wrapper>
      <Layout className="public-layout">
        <MyLayout>
          {children}
        </MyLayout>
      </Layout>
    </Wrapper>
  )
}

export default PublicLayout
