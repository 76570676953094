import styled from 'styled-components'

const GAME_EVENTS = [
  {
    title: 'Every tap is a step forward',
    bg: '/images/landing/battery-bg.png',
    img: '/images/landing/battery.png',
    description:
      'With "Tap to Earn" in Zeus88 GameFi, earn tokens effortlessly with each tap. Boost your rewards through events, missions, and upgrades. Start accumulating tokens and maximizing your earnings now!',
  },
  {
    title: 'Arena of Champions',
    bg: '/images/landing/cup-bg.png',
    img: '/images/landing/cup.png',
    description:
      'Join PvP in Zeus88 GameFi for epic battles where your skills and strategies shine. Compete, earn valuable rewards, and rise in rank with each victory. Step into the arena and claim your glory today! Are you ready to dominate the competition?',
  },
  {
    title: 'Rise to the Challenge Content',
    bg: '/images/landing/sword-bg.png',
    img: '/images/landing/sword.png',
    description:
      'Face epic Player vs Boss (PvB) battles in Zeus88 GameFi! Tackle massive bosses with advanced strategies, earn attractive rewards, and boost your reputation. Gear up and conquer the bosses to claim your rewards today!',
  },
]

const GameAndExperiences = () => {
  const handlePlay = () => {
    window.open('https://t.me/Zeus88Bot_bot/claim', '_blank')
  }
  return (
    <Wrapper>
      <div className='container'>
        <div className='first-block'>
          <div className='left-side'>
            <div>
              <h2>Games And Experiences</h2>
              <p>
                GameFi is a blockchain platform where players earn tokens through Tap to Earn and Fight to Earn modes. Enjoy
                exciting gameplay, extra rewards, and airdrops with transparent updates. Dive in and start making money today!
              </p>
            </div>

            <button onClick={handlePlay} className='gold-button'>
              <span>Play Game</span>
            </button>
          </div>

          <img src='/images/landing/game-n-experience.png' alt='game and experiences' />
        </div>

        <div className='second-block'>
          {GAME_EVENTS.map((item, index) => (
            <div className='event-block' key={index} style={{ backgroundImage: `url(${item.bg})` }}>
              <img className='event-img' src={item.img} alt='event' />
              <h3>{item.title}</h3>
              <p>{item.description}</p>
            </div>
          ))}
        </div>
      </div>
    </Wrapper>
  )
}

export default GameAndExperiences

const Wrapper = styled.div`
  background-image: url('/images/landing/game-and-experiences-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  padding-top: 70px;
  padding-bottom: 70px;

  .container {
    display: flex;
    flex-direction: column;
    gap: 44px;

    .first-block {
      display: flex;
      align-items: center;
      gap: 101px;

      @media screen and (max-width: 1024px) {
        gap: 64px;
      }
      @media screen and (max-width: 768px) {
        flex-direction: column-reverse;
        gap: 32px;
      }

      .left-side {
        display: flex;
        flex-direction: column;
        gap: 24px;

        > div {
          display: flex;
          flex-direction: column;
          gap: 12px;

          h2 {
            font-size: 62px;
            font-weight: 700;
            line-height: 79.13px;
            text-align: left;
            background: linear-gradient(167.47deg, #fdfffd 33.85%, #fffa8e 65.47%, #fffb9f 72.42%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            word-spacing: -20px;
          }

          p {
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            text-align: left;
          }
        }

        button {
          align-self: flex-start;
          background-image: url('/images/landing/gold-btn.png');
          cursor: pointer;
          background-repeat: no-repeat;
          background-size: 100% 100%;
          border: none;
          background-color: transparent;
          padding: 22px 46px;
          color: #000;
          font-size: 26px;
          font-weight: 700;

          @media screen and (max-width: 1024px) {
            font-size: 16px;
          }
        }
      }

      img {
        width: 604px;
        flex-shrink: 0;

        @media screen and (max-width: 1024px) {
          width: 500px;
        }
        @media screen and (max-width: 640px) {
          width: 100%;
        }
      }
    }

    .second-block {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 32px;
      @media screen and (max-width: 1024px) {
        grid-template-columns: repeat(2, 1fr);
      }
      .event-block {
        margin-top: 168px;
        background-position: top;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        padding: 110px 24px 44px 24px;
        height: 360px;
        .event-img {
          position: absolute;
          top: -30%;
          left: 50%;
          transform: translate(-50%);
          width: 224px;
          height: 224px;
          object-fit: cover;
          animation: float 3s ease-in-out infinite;
          @media screen and (max-width: 768px) {
            width: 200px;
            height: 200px;
          }
        }
        h3 {
          color: #fff;
          font-family: Lora;
          font-size: 30px;
          font-weight: 700;
          text-transform: capitalize;
          text-align: center;
          min-height: 86px;
          margin-bottom: 12px;
        }
        p {
          color: #fff;
          font-family: Lora;
          font-size: 14px;
          font-weight: 400;
          text-align: center;
        }
      }
      @media screen and (max-width: 768px) {
        grid-template-columns: 1fr 1fr;
      }

      @media screen and (max-width: 640px) {
        grid-template-columns: 1fr;
      }

      img {
        width: 100%;
      }
    }
  }
  @keyframes float {
    0% {
      transform: translate(-50%, 0);
    }
    50% {
      transform: translate(-50%, -20px);
    }
    100% {
      transform: translate(-50%, 0);
    }
  }
`
