import { createReducer } from '@reduxjs/toolkit'
import * as actions from './actions'

export interface CollectionState {
  userBalance: {
    loading: boolean
    pom: string | number
    ton: string | number
  }
  tokenInfo: any
}

const initialState: CollectionState = {
  userBalance: {
    loading: true,
    pom: '0',
    ton: '0',
  },
  tokenInfo: undefined,
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(actions.setUserBalance, (state, { payload }) => {
      state.userBalance = payload
    })
    .addCase(actions.setTokenInfo, (state, { payload }) => {
      state.tokenInfo = payload
    })
)
