import styled from 'styled-components'

const Airdrop: React.FC = () => {
  return (
    <Wrapper>
      <div className='container'>
        <div className='first-box'>
          <div className='airdrop-button'>
            Total supply: <span>88888888 $ZEUS</span>
          </div>
          <h1 className='title'>Zeus88 Airdrop</h1>
          <p className='description'>
          Join now to win 8,888,888 $ZEUS tokens and countless attractive rewards. The more actively you participate, the higher your chances of receiving rewards!
          </p>
          <p className='description'>
          The Airdrop program is completely free! The higher the level and rank, the more valuable the Zeus88 rewards and gifts for you!
          </p>
          <button className='join-button'>
            <span>Join Now</span>
          </button>
        </div>
        <div className='second-box'>
          <img src='/images/landing/airdrop-left2.png' alt='airdrop' />
          <img src='/images/landing/airdrop-right.png' alt='airdrop' />
        </div>
      </div>
    </Wrapper>
  )
}

export default Airdrop

const Wrapper = styled.div`
  background-image: url('/images/landing/airdrop-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  transform: translateY(-80px);
  background-position: top;
  padding: 77px 0px;

  @media screen and (max-width: 1024px) {
    transform: translateY(-36px);
  }

  @media screen and (max-width: 768px) {
    transform: translateY(-24px);
  }

  @media screen and (max-width: 640px) {
    transform: translateY(-12px);
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 82px;

    @media screen and (max-width: 640px) {
      gap: 64px;
    }

    .first-box {
      display: flex;
      flex-direction: column;
      max-width: 885px;
      align-items: center;
      gap: 16px;

      .airdrop-button {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.24) 0%, rgba(102, 102, 102, 0.24) 100%);
        border: 1px solid #e4ff981f;

        padding: 10px 24px;
        border-radius: 12px;
        position: relative;

        font-size: 24px;
        font-weight: 500;
        line-height: 30.72px;
        text-align: left;

        @media screen and (max-width: 640px) {
          font-size: 18px;
        }

        span {
          font-size: 24px;
          font-weight: 700;
          line-height: 30.72px;
          text-align: left;

          @media screen and (max-width: 640px) {
            font-size: 18px;
          }
        }
      }

      .title {
        font-size: 62px;
        font-weight: 700;
        line-height: 79.13px;
        text-align: center;
        background: linear-gradient(167.47deg, #fdfffd 33.85%, #fffa8e 65.47%, #fffb9f 72.42%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        word-spacing: -20px;

        @media screen and (max-width: 640px) {
          font-size: 32px;
          line-height: 32px;
          word-spacing: -8px;
        }
      }

      .description {
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        text-align: center;

        @media screen and (max-width: 640px) {
          font-size: 14px;
          line-height: 22px;
        }
      }

      .join-button {
        cursor: pointer;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        border: none;
        background-color: transparent;
        padding: 22px 46px;
        color: #000;
        font-size: 26px;
        font-weight: 700;
        background-image: url('/images/landing/gold-btn.png');

        @media screen and (max-width: 1024px) {
          font-size: 16px;
        }
      }
    }

    .second-box {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 32px;
      align-items: end;
      @media screen and (max-width: 640px) {
        grid-template-columns: 1fr;
      }

      img {
        width: 100%;
      }
    }
  }
`
