import { SpaceProps } from 'styled-system'

export const scales = {
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
} as const

export type Scales = typeof scales[keyof typeof scales]

export interface InputProps extends SpaceProps {
  icon?: string
  scale?: Scales
  className?: string
  placeholder?: string
  isSuccess?: boolean
  isWarning?: boolean
}
