import React from 'react'
import styled from 'styled-components'

const ScheduleItem = ({ title, description, color }) => (
  <div className='item-inner'>
    <div className='inner-title'>
      <div className='icon-circle' style={{ background: color }}></div>
      <p>{title}</p>
    </div>
    <div className='inner-desc'>{description}</div>
  </div>
)

const schedules = [
  { title: 'Investors:', description: '4 Months Cliff, 12 Months Linear Vesting', color: '#FC941B' },
  { title: 'KOLs', description: '10% TGE, 2 Month Cliff, 10 Months Linear Vesting', color: '#00FF3F' },
  {
    title: 'Liquidity & Market Making',
    description: '20% TGE, Then 10% Monthly During 4 Months, Then 5% Monthly During 8 Months',
    color: '#AB7E27',
  },
  {
    title: 'Marketing & User Acquisition',
    description: '10% TGE, 5% Week 3, 5% Week 4, 5% Month 1, Then 1% Monthly',
    color: '#00C0FF',
  },
  { title: 'AirDrop', description: '4 Months Cliff, 12 Months Linear Vesting', color: '#AF00D0' },
  { title: 'Users Incentives', description: '2 Months Cliff, Then 2 Years Linear Vesting', color: '#FD4853' },
  { title: 'Team', description: '1 Year Cliff 18 Months Linear Vesting', color: '#FF3F00' },
  { title: 'Partners, Advisors & Consultants', description: '1 Year Cliff 18 Months Linear Vesting', color: '#FF00C0' },
  { title: 'Treasury', description: '6 Months Cliff, 2 Years Linear Vesting', color: '#3F00FF' },
]
const Tokenomics: React.FC = () => {
  return (
    <Wrapper>
      <div className='container'>
        <div className='title'>
          <h2>Tokenomics</h2>
        </div>
        <div className='content'>
          <div className='top'>
            <img src='/images/landing/tokenomics-img.png' alt='' />
          </div>
          <div className='bottom'>
            <div className='schedule-title'>
              <h3>Vesting schedules</h3>
            </div>
            <div className='schedule-content'>
              <Grid>
                {schedules.map((item, i) => (
                  <ScheduleItem key={i} {...item} />
                ))}
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default Tokenomics

const Wrapper = styled.div`
  background-image: url('/images/landing/tokenomics-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transform: translateY(-80px);
  .container {
    padding-top: 56px;
    padding-bottom: 44px;
    @media screen and (max-width: 640px) {
      padding-top: 90px;
    }
    .title {
      /* background-image: url('/images/landing/tokenomics-bg01.png');
      background-repeat: no-repeat;
      background-size: cover; */
      display: flex;
      justify-content: center;
      h2 {
        font-family: Lora;
        font-size: 62px;
        font-weight: 700;
        text-transform: capitalize;
        background: linear-gradient(167deg, #fdfffd 33.85%, #fffa8e 65.47%, #fffb9f 72.42%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        @media screen and (max-width: 640px) {
          font-size: 32px;
        }
      }
    }

    .content {
      margin-top: 106px;
    }
  }
  .bottom {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 768px) {
      margin-top: 40px;
    }
    .schedule-title {
      text-align: center;
      border-radius: 12px 12px 0 0;
      padding: 6px 24px;
      background: linear-gradient(260deg, rgba(0, 0, 0, 0.55) 0%, rgba(33, 33, 33, 0.43) 46%, rgba(0, 0, 0, 0.8) 100%);

      h3 {
        background: linear-gradient(167deg, #fdfffd 33.85%, #fffa8e 65.47%, #fffb9f 72.42%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family: Lora;
        font-size: 24px;
        font-weight: 700;
        @media screen and (max-width: 640px) {
          font-size: 20px;
        }
      }
    }
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1px;
  background: linear-gradient(260deg, rgba(0, 0, 0, 0.55) 0%, rgba(33, 33, 33, 0.43) 46%, rgba(0, 0, 0, 0.8) 100%);
  border-radius: 12px;
  backdrop-filter: blur(22px);
  overflow: hidden;
  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 640px) {
    grid-template-columns: repeat(1, 1fr);
  }
  .item-inner {
    outline: 1px solid rgba(255, 255, 255, 0.12);
    padding: 20px;
    .inner-title {
      color: #fff;
      font-family: Lora;
      font-size: 18px;
      font-weight: 700;
      text-transform: capitalize;
      display: flex;
      gap: 8px;
      align-items: center;
      margin-bottom: 12px;
      .icon-circle {
        min-width: 24px;
        height: 24px;
        border-radius: 50%;
      }
    }
    .inner-desc {
      color: #fff;

      font-family: Lora;
      font-size: 16px;
      font-weight: 400;
      text-transform: capitalize;
    }
  }
`

// const ScheduleItem= styled.div`
// `
