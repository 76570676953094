import React from 'react'

const NextArrow = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" viewBox="0 0 60 70" fill="none">
      <g filter="url(#filter0_d_330_579)">
        <path
          d="M50.1301 27.8244C55.0244 31.4208 55.0244 38.733 50.13 42.3295L15.31 67.9156C9.36624 72.2831 0.98077 68.0389 0.980771 60.663L0.980773 9.49081C0.980773 2.11494 9.36626 -2.12924 15.31 2.23829L50.1301 27.8244Z"
          fill="url(#paint0_linear_330_579)" />
        <path
          d="M49.834 28.2273C54.4564 31.6239 54.4564 38.5299 49.834 41.9265L15.0139 67.5126C9.40038 71.6375 1.48077 67.6291 1.48077 60.663L1.48077 9.49081C1.48077 2.52471 9.40039 -1.48368 15.0139 2.64121L49.834 28.2273Z"
          stroke="url(#paint1_linear_330_579)" />
      </g>
      <defs>
        <filter id="filter0_d_330_579" x="0.980774" y="0.474609" width="58.82" height="69.2046"
                filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                         result="hardAlpha" />
          <feOffset dx="6" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix"
                         values="0 0 0 0 0.00416667 0 0 0 0 0.00416667 0 0 0 0 0.00416667 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_330_579" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_330_579" result="shape" />
        </filter>
        <linearGradient id="paint0_linear_330_579" x1="20.6539" y1="-15" x2="20.6538" y2="85.1538"
                        gradientUnits="userSpaceOnUse">
          <stop stopColor="#FDF53A" />
          <stop offset="1" stopColor="#ED7F0F" />
        </linearGradient>
        <linearGradient id="paint1_linear_330_579" x1="20.6539" y1="-15" x2="20.6538" y2="85.1538"
                        gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF8A00" />
          <stop offset="1" stopColor="#954900" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default NextArrow
