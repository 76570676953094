import styled from 'styled-components'
import Header from './components/Header'
import HeroBanner from './components/HeroBanner'
import CountDown from './components/CountDown'
import GameAndExperiences from './components/GemeAndExperiences'
import HowToEarn from './components/HowToEarn'
import Airdrop from './components/Airdrop'
import Footer from './components/Footer'
import Play from './components/Play'
import Bounce from 'react-reveal/Bounce'
import Fade from 'react-reveal/Fade'
import Zoom from 'react-reveal/Zoom'
import Tokenomics from './components/Tokenomics'

const LandingPage = () => {
  return (
    <Wrapper className=''>
      <Header />
      <Fade left>
        <HeroBanner />
      </Fade>
      <Bounce>
        <CountDown />
      </Bounce>
      <Fade right>
        <GameAndExperiences />
      </Fade>
      <Zoom>
        <HowToEarn />
      </Zoom>
      <Fade right>
        <Airdrop />
      </Fade>
      <Zoom>
        <Tokenomics />
      </Zoom>
      <Bounce>
        <Play />
      </Bounce>
      <Zoom>
        <Footer />
      </Zoom>
    </Wrapper>
  )
}

export default LandingPage

const Wrapper = styled.div`
  color: #fff;
  background-color: #000000;
`
