import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const LoadingStyled = styled.div`
    width: 100vw;
    height: 100vh;
    min-width: 100vw;
    min-height: 100vh;

    position: fixed;
    z-index: 999;
    overflow: hidden;

    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;

    background-image: url('/images/home/background.png');
    background-size: cover;
    background-position: center;
    
    @keyframes breath {
        0% {
            transform: scale(1);
            opacity: 0.8;
        }
        50% {
            transform: scale(1.05);
            opacity: 1;
        }
        100% {
            transform: scale(1);
            opacity: 0.8;
        }
    }

    .loading-content {
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        position: relative;
        animation: breath 2s infinite;
        
        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 200px;
        }
        
        img[alt="logo-ring"] {
            width: 250px;
        }
    }
`

const LoadingPage = ({isSetTimeout = true}) => {
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (!isSetTimeout) return

    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }, [isSetTimeout])
  if (!loading) return <></>
  return (
    <LoadingStyled>
      <div className="loading-content">
        <img src="/images/logo-ring.png" alt={'logo-ring'} />
        <img src="/images/logo.png" alt={'logo'} />
      </div>
    </LoadingStyled>
  )
}

export default LoadingPage
