import { useCallback } from 'react'

const useGetTokenFromDex = (lpAddress: string) => {
  const getData = useCallback(async (cbs?: any) => {
    if (!lpAddress) return

    try {
      return await fetch(`https://api.dexscreener.com/latest/dex/tokens/${lpAddress}`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((result) => {
          if (result?.pairs && result?.pairs?.length > 0) {
            if (cbs) cbs(result?.pairs[0])
            return result?.pairs[0]
          }
        })
    } catch (error) {
      console.error(error)
    }
  }, [lpAddress])

  return { getData }
}

export default useGetTokenFromDex
