import { ENCRYPT_SECRET_KEY } from 'constants/index'
import CryptoJS from 'crypto-js'
import { useCallback } from 'react'
import { generateWallet } from 'services/curl/Auth'
import { generateMnemonic, validateMnemonic } from 'tonweb-mnemonic'

export const encryptData = (data) => CryptoJS.AES.encrypt(data, ENCRYPT_SECRET_KEY).toString()
export const decryptData = (data) => CryptoJS.AES.decrypt(data, ENCRYPT_SECRET_KEY).toString(CryptoJS.enc.Utf8)

const useTonMnemonic = () => {
  const getWalletFromPhase = useCallback(async (mnemonic: string) => {
    const encryptBody = encryptData(mnemonic)

    const res = await generateWallet({
      mnemonic: encryptBody,
    })

    if (res?.status === 201) {
      return {
        walletAddress: res?.data?.data?.walletAddress,
        mnemonic: mnemonic,
      }
    }

    return null
  }, [])

  const generate = useCallback(async () => {
    const mnemonic = await generateMnemonic()
    const result = await getWalletFromPhase(mnemonic?.join(' '))
    return result
  }, [getWalletFromPhase])

  const checkPhase = useCallback(
    async (phase: string, cbe = () => true) => {
      const convertData = phase?.split(' ')
      const isValid = await validateMnemonic(convertData)

      if (isValid) {
        const wallet = await getWalletFromPhase(phase)
        return wallet
      } else {
        cbe && cbe()
      }

      return null
    },
    [getWalletFromPhase]
  )

  return { generate, checkPhase }
}

export default useTonMnemonic
