export enum ChainId {
  MAINNET = 56,
  TESTNET = 97,
  DXD = 9000,
}

/**
 * Represents an ERC20 token with a unique address and some metadata.
 */
export class Token {
  readonly chainId?: number
  readonly address?: string
  readonly decimals?: number
  readonly name?: string
  readonly symbol?: string
  readonly projectLink?: string
  constructor(chainId: ChainId, address: string, decimals: number, symbol?: string, name?: string, projectLink?: string) {
    this.chainId = chainId
    this.address = address
    this.decimals = decimals
    this.symbol = symbol
    this.name = name
    this.projectLink = projectLink
  }
}
