import { createReducer } from '@reduxjs/toolkit'
import * as actions from './actions'
import { ModalType } from '../../types/index'

export interface ModalState {
  modalNotification: ModalType
}

const initialState: ModalState = {
  modalNotification: { toggle: false, dataModal: null },
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(actions.setModalNotification, (state, { payload }) => {
      state.modalNotification = payload
    })
)
