import handle, { removeToken } from './Axios'
import Cookies from 'js-cookie'
import { decryptData, encryptData } from 'hooks/useTonMnemonic'
import { CACHE_USER_DATA } from 'constants/index'

export const logout = () => {
  Cookies.remove(CACHE_USER_DATA)
  localStorage.removeItem(CACHE_USER_DATA)
  removeToken()
}

export const storageUserData = (wallet, token) => {
  const data = {
    address: wallet?.walletAddress,
    phrase: wallet?.mnemonic,
    token,
  }

  const jsonData = JSON.stringify(data)
  const encrypt = encryptData(jsonData)
  Cookies.set(CACHE_USER_DATA, encrypt, { expires: 1000 })
  localStorage.setItem(CACHE_USER_DATA, encrypt)
}

export const checkUserData = () => {
  const cookieData = Cookies.get(CACHE_USER_DATA)
  const storageData = localStorage.getItem(CACHE_USER_DATA)

  if (cookieData || storageData) {
    const walletData = (cookieData && decryptData(cookieData)) || (storageData && decryptData(storageData))
    return JSON.parse(walletData)
  }

  logout()
  return null

}

export const generateWallet = (data) => {
  try {
    return handle.post('/auth/generate-wallet', data)
  } catch (error) {
    const { response, message } = error
    if (response) {
      return response
    }
    return message
  }
}

export const apiLogin = (data) => {
  try {
    return handle.post('/auth/login', data)
  } catch (error) {
    const { response, message } = error
    if (response) {
      return response
    }
    return message
  }
}
