import React, { FC } from 'react'
import styled from 'styled-components'
import Modal from './Modal/QRModal'
import { checkUserData } from 'services/curl/Auth'
import { formatCode } from 'helpers/CommonHelper'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { message } from 'antd'
import QRCode from 'qrcode.react'

const QRWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  .description {
    padding: 0 0.5rem;
    margin-top: 1rem;
  }

  .qr-box {
    width: 70%;
    margin: 0 auto;
    aspect-ratio: 1;
    background-color: #fff;
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;

    svg {
      width: 100%;
      height: 100%;
    }
  }
`

const CopyButton = styled.button`
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  position: relative;

  font-size: 1.2rem;
  color: #fff;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #127d00;
  font-family: Jua;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  img {
    height: 50px;
  }

  div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    gap: 5px;

    span {
      margin-top: 2px;
    }
  }
`

interface QRModalBodyProps {
  show: boolean
  onClose: () => void
}

const QRModalBody: FC<QRModalBodyProps> = ({ show, onClose }) => {
  const userData = checkUserData()

  return (
    <Modal show={show} onClose={onClose}>
      <div className={'modal-header'}>
        <CopyToClipboard onCopy={() => message.success('Copied successful', 2)} text={userData?.address}>
          <CopyButton>
            <img src='/images/home/green-btn.png' alt='View' />
            <div>
              <svg height={14} viewBox='0 0 22 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M5.61013 3.37387C5.61013 2.52013 6.29452 1.82804 7.13875 1.82804H14.5503V5.34129C14.5503 6.86767 15.7739 8.10505 17.2832 8.10505H19.9236V18.4574C19.9236 19.3112 19.2392 20.0033 18.395 20.0033H7.13875C6.29452 20.0033 5.61013 19.3112 5.61013 18.4574V3.37387ZM15.7546 5.34129V2.04977L19.8146 6.88712H17.2832C16.439 6.88712 15.7546 6.19503 15.7546 5.34129ZM4.41805 3.11011C4.54937 1.70745 5.71734 0.610107 7.13875 0.610107H16.126L21.128 6.56985V18.4574C21.128 19.9838 19.9044 21.2212 18.395 21.2212H16.836C16.6949 22.6253 15.5143 23.7212 14.0788 23.7212H2.89931C1.36872 23.7212 0.12793 22.4753 0.12793 20.9385V5.89284C0.12793 4.35598 1.36872 3.11011 2.89931 3.11011H4.41805ZM15.6033 21.2212H7.13875C5.62936 21.2212 4.40576 19.9838 4.40576 18.4574V4.3364H2.89931C2.04321 4.3364 1.34921 5.03324 1.34921 5.89284V20.9385C1.34921 21.7981 2.04321 22.4949 2.89931 22.4949H14.0788C14.8387 22.4949 15.4709 21.9458 15.6033 21.2212Z'
                  fill='#24550B'
                />
              </svg>
              <span>Copy</span>
            </div>
          </CopyButton>
        </CopyToClipboard>
      </div>
      <QRWrapper>
        <div className={'qr-box'}>
          <QRCode id='iconCheckmarkLg' renderAs='svg' value={userData?.address} includeMargin fgColor='#000' bgColor='#fff' />
        </div>
        <div className={'description'}>
          Your address
          <br />
          {formatCode(userData?.address, 8, 12)}
        </div>
      </QRWrapper>
    </Modal>
  )
}

export default QRModalBody
