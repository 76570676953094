import classNames from 'classnames'
import { MIN_TON_FEE } from 'constants/index'
import { encryptData } from 'hooks/useTonMnemonic'
import { FC, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { checkUserData } from 'services/curl/Auth'
import useGameService from 'services/curl/Games'
import { AppState } from 'state'
import { setModalNotification } from 'state/modal/actions'
import { setFeeGameUpgrade } from 'state/user/actions'
import styled from 'styled-components'
import { Box } from 'widgets/Box'
import GoldButton from './GoldButton'
import GreenButton from './GreenButton'
import { ModalProps } from './Modal'
import HomeModal from './Modal/HomeModal'

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: space-around;
  align-items: center;

  img[alt='rocket'] {
    width: 25%;
    position: absolute;
    top: -15%;
    right: -10%;
  }

  .title {
    color: #c15a00;
    font-family: Jua;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
  }

  .subtitle {
    color: rgba(193, 90, 0, 0.69);
    font-size: 0.875rem;
    font-family: Jua;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
  }

  .level {
    display: flex;
    align-items: center;
    width: 100%;

    .thumb {
      width: 60px;
      flex-shrink: 0;
    }

    .track {
      flex: 1;

      .info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 0.7rem;

        color: #cc3606;
        font-family: Jua;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .base-track {
        width: 100%;
        height: 25px;
        border-radius: 0 100px 100px 0;
        border: 3px solid #fff;
        border-left: transparent;
        background: linear-gradient(180deg, #04566e 0%, #00a3ca 100%);
        position: relative;

        .track {
          height: 100%;
          background: linear-gradient(90deg, #a61e60 0%, #ffd22f 100%);
          border-radius: 0 100px 100px 0;
          position: absolute;
          top: 0;
          left: 0;
          box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.25);
        }
      }
    }
  }

  .actions {
    display: flex;
    justify-content: space-around;
    color: #fff;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #d18d00;
    font-family: Jua;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    gap: 1rem;

    > div {
      &.disabled {
        opacity: 0.5;
      }

      > span {
        font-size: 1.25rem;
      }
    }
  }
`

interface IRocketModalBody extends ModalProps {
  gameData: any
  setGameData: any
}

const RocketModalBody: FC<IRocketModalBody> = ({ show, onClose, gameData, setGameData }) => {
  const dispatch = useDispatch()
  const { fetchUpgradeRocket, fetchFeeUpgrade } = useGameService()

  const userData = checkUserData()
  const { rocketPoms } = useSelector((state: AppState) => state.user.feeGameUpgrade)
  const { userBalance } = useSelector((state: AppState) => state.wallet)

  const isDisabled = useMemo(() => {
    if (!rocketPoms || userBalance?.loading || !+userBalance?.pom) return true
    if (rocketPoms > +userBalance?.pom) return true
    if (+gameData?.levelRocket === 30) return true
    return false
  }, [rocketPoms, userBalance?.loading, userBalance?.pom, gameData?.levelRocket])

  const handleConfirm = async () => {
    if (isDisabled || !gameData?.id) return

    if (gameData?.startClaim === '0') {
      dispatch(setModalNotification({ toggle: true, dataModal: { message: 'Claim first time before upgrade' } }))
      return
    }

    if (+userBalance?.ton < MIN_TON_FEE) {
      dispatch(setModalNotification({ toggle: true, dataModal: { message: 'Min gas fee for upgrade 0.05TON' } }))
      return
    }

    dispatch(
      setModalNotification({
        toggle: true,
        dataModal: {
          message: 'Upgrading',
          buttonDisabled: true,
          loading: true,
        },
      })
    )

    const payload = {
      id: gameData?.id,
      body: {
        mnemonic: encryptData(userData?.phrase),
      },
    }

    await fetchUpgradeRocket(
      payload,
      async (res) => {
        setGameData((prev) => ({
          ...prev,
          data: res?.data,
        }))
        dispatch(setModalNotification({ toggle: true, dataModal: { message: 'Upgrade success!!!' } }))
        await fetchFeeUpgrade(gameData?.id, (res) => dispatch(setFeeGameUpgrade(res?.data)))
      },
      () => dispatch(setModalNotification({ toggle: true, dataModal: { message: 'Upgrade failed' } }))
    )
  }

  return (
    <HomeModal show={show} onClose={onClose}>
      <Wrapper>
        <img src='/images/home/rocket-3x.png' alt='rocket' />
        <div>
          <div className={'title'}>Rockets</div>
          <div className={'subtitle'}>Increase speed to get more item</div>
        </div>
        <div className={'level'}>
          <div className='thumb'>
            <img src='/images/poms/level-5.png' alt='level-5' />
          </div>
          <div className='track'>
            <div className='info'>
              <span>Level {gameData?.levelRocket}</span>
              <span>Speed {gameData?.speedRocket}h</span>
            </div>
            <div className='base-track'>
              <div
                className='track'
                style={{
                  width: ((+gameData?.levelRocket || 0) / 30) * 100,
                }}
              ></div>
            </div>
            <div>&nbsp;</div>
          </div>
        </div>
        <div className={'actions'}>
          <GoldButton width={100} onClick={onClose}>
            Cancel
          </GoldButton>
          <GreenButton width={100} className={classNames({ disabled: isDisabled })} onClick={handleConfirm}>
            {rocketPoms} <Box as='img' src='/images/home/paw.png' alt='' width={16} position='relative' bottom='1px' />
          </GreenButton>
        </div>
      </Wrapper>
    </HomeModal>
  )
}

export default RocketModalBody
