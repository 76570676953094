import React, { ReactNode } from 'react'
import { Provider } from 'react-redux'
import store from './state'
import { ThemeContextProvider } from './ThemeContext'

const Providers: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Provider store={store}>
      <ThemeContextProvider>{children}</ThemeContextProvider>
    </Provider>
  )
}

export default Providers
