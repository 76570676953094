import { useCallback } from 'react'
import handle, { getAffiliate } from './Axios'

export const getBalances = () => {
  try {
    return handle.get('/transactions/balances')
  } catch (error) {
    const { response, message } = error
    if (response) {
      return response
    }
    return message
  }
}

export const getMyProfile = () => {
  try {
    return handle.get('/profile/me')
  } catch (error) {
    const { response, message } = error
    if (response) {
      return response
    }
    return message
  }
}

export const updateProfile = (body) => {
  try {
    return handle.put('/profile', body)
  } catch (error) {
    const { response, message } = error
    if (response) {
      return response
    }
    return message
  }
}

const useUserService = () => {
  const fetchBalances = useCallback(async (cbs, cbe) => {
    try {
      const res = await getBalances()
      if (!res) return
      if (cbs) cbs(res?.data)
      return res?.data
    } catch (error) {
      if (cbe) cbe(error)
    }
  }, [])

  const fetchMyProfile = useCallback(async (cbs, cbe) => {
    try {
      const res = await getMyProfile()
      if (!res) return
      if (cbs) cbs(res?.data)
      return res?.data
    } catch (error) {
      if (cbe) cbe(error)
    }
  }, [])

  const fetchUpdateProfile = useCallback(async (body, cbs, cbe) => {
    try {
      const res = await updateProfile(body)
      if (!res) return
      if (cbs) cbs(res?.data)
    } catch (error) {
      if (cbe) cbe(error)
    }
  }, [])

  const fetchAffiliate = useCallback(async (address, cbs, cbe) => {
    try {
      const res = await getAffiliate(address)
      if (!res) return
      if (cbs) cbs(res?.data)
      return res?.data
    } catch (error) {
      if (cbe) cbe(error)
    }
  }, [])

  return { fetchBalances, fetchMyProfile, fetchUpdateProfile, fetchAffiliate }
}

export default useUserService
