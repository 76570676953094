import { createReducer } from '@reduxjs/toolkit'
import * as actions from './actions'

export interface CollectionState {
  userInfo: any
  feeGameUpgrade: any
  myAffiliate: any
}

const initialState: CollectionState = {
  userInfo: undefined,
  myAffiliate: undefined,
  feeGameUpgrade: {
    lightningPoms: 0,
    skeletonTon: 0,
    rocketPoms: 0,
  },
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(actions.setUserInfo, (state, { payload }) => {
      state.userInfo = payload
    })
    .addCase(actions.setFeeGameUpgrade, (state, { payload }) => {
      state.feeGameUpgrade = payload
    })
    .addCase(actions.setMyAffiliate, (state, { payload }) => {
      state.myAffiliate = payload
    })
)
