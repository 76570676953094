import React from 'react'
import ReactDOM from 'react-dom'
import TimeAgo, { LocaleData } from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import ru from 'javascript-time-ago/locale/ru'
import ResetCSS from './ResetCSS'
import GlobalStyle from './style/Global'
import Providers from './Providers'
import App from './pages/App'
import reportWebVitals from './reportWebVitals'
import 'react-toastify/dist/ReactToastify.css'
import 'antd/dist/antd.css'

if ('ethereum' in window) (window.ethereum as any).autoRefreshOnNetworkChange = false

TimeAgo.addDefaultLocale(en as LocaleData)
TimeAgo.addLocale(ru as LocaleData)

window.addEventListener('error', () => {
  localStorage?.removeItem('redux_localstorage_simple_lists')
})

ReactDOM.render(
  <React.StrictMode>
    <Providers>
      <ResetCSS />
      <GlobalStyle />
      <App />
    </Providers>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
