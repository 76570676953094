import classNames from 'classnames'
import { MIN_TON_FEE } from 'constants/index'
import { encryptData } from 'hooks/useTonMnemonic'
import { FC, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { checkUserData } from 'services/curl/Auth'
import useGameService from 'services/curl/Games'
import { AppState } from 'state'
import { setModalNotification } from 'state/modal/actions'
import { setFeeGameUpgrade } from 'state/user/actions'
import styled from 'styled-components'
import GoldButton from './GoldButton'
import GreenButton from './GreenButton'
import { ModalProps } from './Modal'
import HomeModal from './Modal/HomeModal'

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: space-around;
  align-items: center;

  img[alt='food'] {
    width: 25%;
    position: absolute;
    top: -10%;
    right: -8%;
  }

  .title {
    color: #c15a00;
    font-family: Jua;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
  }

  .subtitle {
    color: rgba(193, 90, 0, 0.69);
    font-size: 0.875rem;
    font-family: Jua;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
  }

  .level {
    display: flex;
    align-items: center;
    width: 100%;

    .thumb {
      width: 60px;
      height: 60px;
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background: radial-gradient(50% 50% at 50% 50%, #62f6ff 0%, #0f8b93 100%);
      border-radius: 1rem;
      border: 2px solid #5ff9fe;

      img {
        width: 50px;
        margin: auto;
      }
    }

    .track {
      flex: 1;

      .info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 0.7rem;

        color: #cc3606;
        font-family: Jua;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .base-track {
        width: 100%;
        height: 25px;
        border-radius: 0 100px 100px 0;
        border: 3px solid #fff;
        border-left: transparent;
        background: linear-gradient(180deg, #04566e 0%, #00a3ca 100%);
        position: relative;

        .track {
          height: 100%;
          background: linear-gradient(90deg, #a61e60 0%, #ffd22f 100%);
          border-radius: 0 100px 100px 0;
          position: absolute;
          top: 0;
          left: 0;
          box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.25);
        }
      }
    }
  }

  .actions {
    display: flex;
    justify-content: space-around;
    color: #fff;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #d18d00;
    font-family: Jua;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    gap: 1rem;

    > div {
      &.disabled {
        opacity: 0.5;
      }

      > span {
        font-size: 1.25rem;
      }
    }
  }
`

const TonBtn = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  div {
    background: #0088cc;
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    img {
      height: 8px;
    }
  }
`

interface IFoodModalBody extends ModalProps {
  gameData: any
  setGameData: any
}

const FoodModalBody: FC<IFoodModalBody> = ({ show, onClose, gameData, setGameData }) => {
  const dispatch = useDispatch()
  const { fetchBuySkeleton, fetchFeeUpgrade } = useGameService()

  const userData = checkUserData()
  const { skeletonTon } = useSelector((state: AppState) => state.user.feeGameUpgrade)
  const { userBalance } = useSelector((state: AppState) => state.wallet)

  const isDisabled = useMemo(() => {
    if (!skeletonTon || userBalance?.loading || !+userBalance?.ton) return true
    if (skeletonTon > +userBalance?.ton) return true
    if (+gameData?.skeletonDog === 5) return true
    return false
  }, [skeletonTon, userBalance?.loading, userBalance?.ton, gameData?.skeletonDog])

  const renderRate = useMemo(() => {
    if (gameData?.skeletonDog === 1) return 1.25
    if (gameData?.skeletonDog === 2) return 1.5
    if (gameData?.skeletonDog === 3) return 1.75
    if (gameData?.skeletonDog === 4) return 2
    if (gameData?.skeletonDog === 5) return 2.5

    return 1
  }, [gameData?.skeletonDog])

  const handleConfirm = async () => {
    if (isDisabled || !gameData?.id) return

    // if (gameData?.startClaim === '0') {
    //   dispatch(setModalNotification({ toggle: true, dataModal: { message: 'Claim first time before buy skeleton' } }))
    //   return
    // }

    if (+userBalance?.ton < MIN_TON_FEE + skeletonTon) {
      dispatch(setModalNotification({ toggle: true, dataModal: { message: 'Min gas fee for buy 0.05TON' } }))
      return
    }

    dispatch(
      setModalNotification({
        toggle: true,
        dataModal: {
          message: 'Buying',
          buttonDisabled: true,
          loading: true,
        },
      })
    )

    const payload = {
      id: gameData?.id,
      body: {
        mnemonic: encryptData(userData?.phrase),
      },
    }

    await fetchBuySkeleton(
      payload,
      async (res) => {
        setGameData((prev) => ({
          ...prev,
          data: res?.data,
        }))
        dispatch(setModalNotification({ toggle: true, dataModal: { message: 'Buy success!!!' } }))
        await fetchFeeUpgrade(gameData?.id, (res) => dispatch(setFeeGameUpgrade(res?.data)))
      },
      () => dispatch(setModalNotification({ toggle: true, dataModal: { message: 'Buy failed' } }))
    )
  }

  return (
    <HomeModal show={show} onClose={onClose}>
      <Wrapper>
        <img src='/images/home/bone-3x.png' alt='food' />
        <div>
          <div className={'title'}>Food</div>
          <div className={'subtitle'}>Increase the number of coins mined per second</div>
        </div>
        <div className={'level'}>
          <div className='thumb'>
            <img src='/images/home/bone.png' alt='bone' />
          </div>
          <div className='track'>
            <div className='info'>
              <span>Level {gameData?.skeletonDog}</span>
              <span>x {renderRate}</span>
            </div>
            <div className='base-track'>
              <div
                className='track'
                style={{
                  width: (gameData?.skeletonDog / 5) * 100,
                }}
              ></div>
            </div>
            <div>&nbsp;</div>
          </div>
        </div>
        <div className={'actions'}>
          <GoldButton width={100} onClick={onClose}>
            Cancel
          </GoldButton>
          <GreenButton width={100} className={classNames({ disabled: isDisabled })} onClick={handleConfirm}>
            <TonBtn>
              <div>
                <img src='/images/tokens/ton.png' alt='ton' />
              </div>
              <p>{skeletonTon}</p>
            </TonBtn>
          </GreenButton>
        </div>
      </Wrapper>
    </HomeModal>
  )
}

export default FoodModalBody
