import React, { ReactNode, useEffect, useRef } from 'react'
import styled from 'styled-components'

const Button = styled.div`
    width: 100px;
    margin: 0 auto;
    color: #FFF;
    position: relative;
    white-space: nowrap;
    outline: none;
    border: none;
    background: none;
    cursor: pointer;
    padding: 0;

    span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`

const GoldButton = ({ children, onClick, width }: { children: ReactNode, onClick?: () => void, width?: number }) => {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLSpanElement>(null)

  useEffect(() => {
    const wrapper = wrapperRef.current
    const content = contentRef.current
    if (content && wrapper) {
      wrapper.style.width = `${width ? width : content.offsetWidth + 40}px`
    }
  }, [width])

  return (
    <Button ref={wrapperRef} onClick={onClick}>
      <img src="/images/home/gold-btn.png" alt="gold-btn" />
      <span ref={contentRef}>{children}</span>
    </Button>
  )
}

export default GoldButton
