import React, { FC } from 'react'
import styled from 'styled-components'
import { ModalProps } from './index'
import useOnClickOutside from '../../../hooks/useOnClickOutside'

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;

  * {
    font-family: Jua;
  }

  .modal {
    width: 80%;
    max-width: 280px;
    min-height: 100px;
    max-height: calc(100% - 2rem);
    position: relative;

    .modal-content {
      position: absolute;
      width: 85%;
      height: 70%;
      top: 2.5%;
      left: 50%;
      transform: translate(-50%, 0);
    }

    .close-btn {
      position: absolute;
      bottom: 3%;
      left: 50%;
      transform: translate(-50%, 0);
      width: 25%;
      aspect-ratio: 1;
      cursor: pointer;
      outline: none;
      border: none;
      background: transparent;
    }
  }
`

const HomeModal: FC<ModalProps> = ({ children, onClose, show }) => {
  const ref = React.useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, onClose)

  return show ? (
    <ModalWrapper>
      <div className={'modal'} ref={ref}>
        <img src='/images/poms/bang-1.png' alt='bg' />
        <div className='modal-content'>{children}</div>
        <button onClick={onClose} className={'close-btn'}></button>
      </div>
    </ModalWrapper>
  ) : null
}

export default HomeModal
