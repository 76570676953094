import React from 'react'

const PrevArrow = ({...props}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" viewBox="0 0 60 70" fill="none" {...props}>
      <g filter="url(#filter0_d_330_578)">
        <path
          d="M9.86995 27.8244C4.97559 31.4208 4.9756 38.733 9.86996 42.3295L44.69 67.9156C50.6338 72.2831 59.0192 68.0389 59.0192 60.663L59.0192 9.49081C59.0192 2.11494 50.6337 -2.12924 44.69 2.23829L9.86995 27.8244Z"
          fill="url(#paint0_linear_330_578)" />
        <path
          d="M10.166 28.2273C5.54357 31.6239 5.54358 38.5299 10.166 41.9265L44.9861 67.5126C50.5996 71.6375 58.5192 67.6291 58.5192 60.663L58.5192 9.49081C58.5192 2.52471 50.5996 -1.48368 44.9861 2.64121L10.166 28.2273Z"
          stroke="url(#paint1_linear_330_578)" />
      </g>
      <defs>
        <filter id="filter0_d_330_578" x="0.199158" y="0.474609" width="58.8201" height="69.2046"
                filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                         result="hardAlpha" />
          <feOffset dx="-6" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix"
                         values="0 0 0 0 0.00416667 0 0 0 0 0.00416667 0 0 0 0 0.00416667 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_330_578" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_330_578" result="shape" />
        </filter>
        <linearGradient id="paint0_linear_330_578" x1="39.3461" y1="-15" x2="39.3462" y2="85.1538"
                        gradientUnits="userSpaceOnUse">
          <stop stopColor="#FDF53A" />
          <stop offset="1" stopColor="#ED7F0F" />
        </linearGradient>
        <linearGradient id="paint1_linear_330_578" x1="39.3461" y1="-15" x2="39.3462" y2="85.1538"
                        gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF8A00" />
          <stop offset="1" stopColor="#954900" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default PrevArrow
