import { getDateToDate } from 'helpers/Date'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

interface CountDownType {
  weekdays: number
  days: number
  hours: number
  minutes: number
  seconds: number
  totalRemain: number
}

interface CountDownProps {
  remains: number
  className?: string
  style?: any
  render?: any
}

const WrapperCountDown = styled.div`
  p {
    display: contents;
  }
`

const CountDownCustom: React.FC<CountDownProps> = ({ remains, className, style, render, ...props }) => {
  const [counter, updateCounter] = useState<CountDownType>({
    weekdays: 0,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
    totalRemain: 0,
  })

  useEffect(() => {
    let timer: any = 0
    function getTime() {
      const currentTime = new Date().getTime()
      const toTime = new Date(remains).getTime()
      if (toTime - currentTime > 0) {
        updateCounter({ ...(getDateToDate(currentTime, toTime) as any), totalRemain: toTime - currentTime })
      } else {
        updateCounter({
          weekdays: 0,
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
          totalRemain: 0,
        })

        clearInterval(timer)
      }
    }
    getTime()

    timer = setInterval(() => getTime(), 1000)
    return () => {
      clearInterval(timer)
    }
  }, [remains])

  return (
    <WrapperCountDown className={className} style={style} {...props}>
      {/* <p>
        {(counter.weekdays * 7 + counter.days).toString().padStart(2, '0')}
        {render?.days || ''} :{' '}
      </p> */}
      <p>
        {counter.hours.toString().padStart(2, '0')}
        {render?.hours || ''} :{' '}
      </p>
      <p>
        {counter.minutes.toString().padStart(2, '0')}
        {render?.minutes || ''} :{' '}
      </p>
      <p>
        {counter.seconds.toString().padStart(2, '0')}
        {render?.seconds || ''}
      </p>
    </WrapperCountDown>
  )
}

export default React.memo(CountDownCustom)
