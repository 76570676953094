import { useCallback } from 'react'
import handle from './Axios'

export const getCharacters = (params) => {
  try {
    return handle.get('/games/all-characters', {
      params,
    })
  } catch (error) {
    const { response, message } = error
    if (response) {
      return response
    }
    return message
  }
}

export const claimReward = (id, body) => {
  try {
    return handle.post(`/games/claim/${id}`, body)
  } catch (error) {
    const { response, message } = error
    if (response) {
      return response
    }
    return message
  }
}

export const getMissions = (params) => {
  try {
    return handle.get('/games/all-missions', {
      params,
    })
  } catch (error) {
    const { response, message } = error
    if (response) {
      return response
    }
    return message
  }
}

export const updateMission = (id, body) => {
  try {
    return handle.post(`/games/update-mission/${id}`, body)
  } catch (error) {
    const { response, message } = error
    if (response) {
      return response
    }
    return message
  }
}

export const calculateProgress = (id) => {
  try {
    return handle.post(`/games/calculate-progress/${id}`)
  } catch (error) {
    const { response, message } = error
    if (response) {
      return response
    }
    return message
  }
}

export const getFeeUpgrade = (id) => {
  try {
    return handle.get(`/games/feeUpgrade/${id}`)
  } catch (error) {
    const { response, message } = error
    if (response) {
      return response
    }
    return message
  }
}

export const upgradeRocket = (id, body) => {
  try {
    return handle.post(`/games/up-rocket/${id}`, body)
  } catch (error) {
    const { response, message } = error
    if (response) {
      return response
    }
    return message
  }
}

export const upgradeLightning = (id, body) => {
  try {
    return handle.post(`/games/up-lightning/${id}`, body)
  } catch (error) {
    const { response, message } = error
    if (response) {
      return response
    }
    return message
  }
}

export const buySkeleton = (id, body) => {
  try {
    return handle.post(`/games/buy-skeleton/${id}`, body)
  } catch (error) {
    const { response, message } = error
    if (response) {
      return response
    }
    return message
  }
}

export const transfer = (body) => {
  try {
    return handle.post('/games/transfer', body)
  } catch (error) {
    const { response, message } = error
    if (response) {
      return response
    }
    return message
  }
}

const useGameService = () => {
  const fetchCharacters = useCallback(async (params, cbs, cbe) => {
    try {
      const res = await getCharacters(params)
      if (!res) return
      if (cbs) cbs(res?.data)
    } catch (error) {
      if (cbe) cbe(error)
    }
  }, [])

  const fetchClaimReward = useCallback(async (payload, cbs, cbe) => {
    const { id, body } = payload
    try {
      const res = await claimReward(id, body)
      if (!res) return
      if (cbs) cbs(res?.data)
    } catch (error) {
      if (cbe) cbe(error)
    }
  }, [])

  const fetchMissions = useCallback(async (params, cbs, cbe) => {
    try {
      const res = await getMissions(params)
      if (!res) return
      if (cbs) cbs(res?.data)
    } catch (error) {
      if (cbe) cbe(error)
    }
  }, [])

  const fetchUpdateMission = useCallback(async (payload, cbs, cbe) => {
    const { id, body } = payload
    try {
      const res = await updateMission(id, body)
      if (!res) return
      if (cbs) cbs(res?.data)
    } catch (error) {
      if (cbe) cbe(error)
    }
  }, [])

  const fetchCalculateProgress = useCallback(async (id, cbs, cbe) => {
    try {
      const res = await calculateProgress(id)
      if (!res) return
      if (cbs) cbs(res?.data)
    } catch (error) {
      if (cbe) cbe(error)
    }
  }, [])

  const fetchFeeUpgrade = useCallback(async (id, cbs, cbe) => {
    try {
      const res = await getFeeUpgrade(id)
      if (!res) return
      if (cbs) cbs(res?.data)
    } catch (error) {
      if (cbe) cbe(error)
    }
  }, [])

  const fetchUpgradeRocket = useCallback(async (payload, cbs, cbe) => {
    const { id, body } = payload
    try {
      const res = await upgradeRocket(id, body)
      if (!res) return
      if (cbs) cbs(res?.data)
    } catch (error) {
      if (cbe) cbe(error)
    }
  }, [])

  const fetchUpgradeLightning = useCallback(async (payload, cbs, cbe) => {
    const { id, body } = payload
    try {
      const res = await upgradeLightning(id, body)
      if (!res) return
      if (cbs) cbs(res?.data)
    } catch (error) {
      if (cbe) cbe(error)
    }
  }, [])

  const fetchBuySkeleton = useCallback(async (payload, cbs, cbe) => {
    const { id, body } = payload
    try {
      const res = await buySkeleton(id, body)
      if (!res) return
      if (cbs) cbs(res?.data)
    } catch (error) {
      if (cbe) cbe(error)
    }
  }, [])

  const fetchTransfer = useCallback(async (payload, cbs, cbe) => {
    try {
      const res = await transfer(payload)
      if (!res) return
      if (cbs) cbs(res?.data)
    } catch (error) {
      if (cbe) cbe(error)
    }
  }, [])

  return {
    fetchCharacters,
    fetchClaimReward,
    fetchMissions,
    fetchUpdateMission,
    fetchCalculateProgress,
    fetchFeeUpgrade,
    fetchUpgradeRocket,
    fetchUpgradeLightning,
    fetchBuySkeleton,
    fetchTransfer,
  }
}

export default useGameService
