// import { applyHmacAndEncryption, setAuthToken } from 'library/FetchHelper'
import { ENCRYPT_SECRET_KEY } from 'constants/index'
import CryptoJS from 'crypto-js'
import { useCallback } from 'react'
import { toast } from 'react-toastify'
import { apiLogin } from 'services/curl/Auth'
import { addToken, removeToken } from 'services/curl/Axios'
import { v4 as uuid } from 'uuid'

const useAuthentication = () => {
  const login = useCallback(async (walletAddress: string, cbs = () => true, cbe = () => true) => {
    try {
      const loginUUID = uuid()
      const deadline = Math.round((new Date().getTime() + 5 * 60000) / 1000)
      const message = `Welcome to Poms!\nClick to sign in and accept the Poms Terms of Service.\nYour authentication status will reset after 24 hours.\nWallet address: ${walletAddress}.\nDeadline: ${deadline}.\nUUID: ${loginUUID}.`
      const messageEncrypted = CryptoJS.AES.encrypt(message, ENCRYPT_SECRET_KEY).toString()

      const body = {
        walletAddress,
        deadline,
        uuid: loginUUID,
        sig: messageEncrypted,
      }

      const resLogin = await apiLogin(body)

      if (resLogin?.status !== 201) {
        toast.error('Login error')
        removeToken()
        cbe && cbe(resLogin)
        return
      }

      const token = resLogin?.data?.data?.token
      addToken(token)
      toast.success('Login successful')
      cbs && cbs(token)
    } catch (e: any) {
      cbe && cbe(e)
      toast.error(e?.message)
    }
  }, [])

  return { login }
}

export default useAuthentication
