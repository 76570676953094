import { DefaultTheme } from 'styled-components'
import base from './base'
import { lightColors } from './colors'
import { dark as darkRadio } from 'widgets/Radio/theme'

const lightTheme: DefaultTheme = {
  ...base,
  isDark: false,
  colors: lightColors,
  radio: darkRadio,
}

export default lightTheme
