import { Layout } from 'antd'
import { TOKEN_TON } from 'constants/index'
import useAuthentication from 'hooks/useAuthentication'
import useGetTokenFromDex from 'hooks/useGetTokenFromDex'
import useInterval from 'hooks/useInterval'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { checkUserData, logout, storageUserData } from 'services/curl/Auth'
import useUserService from 'services/curl/User'
import { setMyAffiliate, setUserInfo } from 'state/user/actions'
import { setTokenInfo, setUserBalance } from 'state/wallet/actions'
import styled from 'styled-components'
import useTheme from '../hooks/useTheme'

const Wrapper = styled.div`
  overflow: hidden;
  position: relative;
`
const MyLayout = styled(Layout)`
  width: 100%;
  height: 100vh;
  background-image: url('/images/home/background.png');
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;

  .layout-header {
    height: 80px;
    padding: 1rem;
    display: flex;
    align-items: end;
    justify-content: space-between;
    position: relative;
    z-index: 5;

    * {
      font-family: Jua;
    }

    &.port-page-header {
      img {
        width: 35px;
      }
    }
  }

  .layout-content {
    flex: 1;
    overflow: auto;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, #000000 100%);
  }

  .port-page-header {
    background: rgba(0, 0, 0, 0.5);

    & + .layout-content {
      background: rgba(0, 0, 0, 0.5);
    }
  }
`

const PrivateLayout = ({ children }) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const userData = checkUserData()
  const { showPortPage } = useTheme()
  const { fetchMyProfile, fetchBalances, fetchAffiliate } = useUserService()
  const { getData: fetchTokenData } = useGetTokenFromDex(TOKEN_TON)
  const { login } = useAuthentication()

  useEffect(() => {
    if (!userData?.address) return

    fetchMyProfile(
      (res) => dispatch(setUserInfo(res?.data)),
      async (err) => {
        const formatErr = JSON.parse(JSON.stringify(err))

        if (formatErr?.message === 'Request failed with status code 400') {
          const wallet = {
            walletAddress: userData?.address,
            mnemonic: userData?.phrase,
          }

          await login(
            userData?.address,
            (token) => {
              storageUserData(wallet, token)
            },
            () => {
              logout()
              setTimeout(() => history.push('/'), 3000)
            }
          )
        }
      }
    )
    fetchAffiliate(userData?.address, (res) => {
      dispatch(setMyAffiliate(res?.data?.tree || {}))
    })
  }, [fetchMyProfile, dispatch, userData, fetchAffiliate, login, history])

  useInterval(async () => {
    fetchBalances((res) => dispatch(setUserBalance({ ...res?.data, loading: false })))
    fetchTokenData((res) => dispatch(setTokenInfo(res)))
  }, 10000)

  return (
    <Wrapper>
      <Layout className={`private-layout ${showPortPage ? 'darken' : ''}`}>
        <MyLayout>{children}</MyLayout>
      </Layout>
    </Wrapper>
  )
}

export default PrivateLayout
