import classNames from 'classnames'
import CountDownCustom from 'components/CountDown/CountDownCustom'
import { MIN_TON_FEE } from 'constants/index'
import { encryptData } from 'hooks/useTonMnemonic'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { checkUserData } from 'services/curl/Auth'
import useGameService from 'services/curl/Games'
import { AppState } from 'state'
import { setModalNotification } from 'state/modal/actions'
import { setFeeGameUpgrade } from 'state/user/actions'
import styled from 'styled-components'
import { addCommasToNumber } from 'utils'
import { Box, Flex, Skeleton, Text } from 'widgets'
import FoodModalBody from 'widgets/Poms/FoodModalBody'
import LoadingPage from 'widgets/Poms/LoadingPage'
import NextArrow from 'widgets/Poms/NextArrow'
import PrivateLayoutWithDock from '../../layouts/PrivateLayoutWithDock'
import GoldButton from '../../widgets/Poms/GoldButton'
import GreenButton from '../../widgets/Poms/GreenButton'
import LightningModalBody from '../../widgets/Poms/LightningModalBody'
import PrevArrow from '../../widgets/Poms/PrevArrow'
import RocketModalBody from '../../widgets/Poms/RocketModalBody'
import { Actions, BalanceBlock, BoneBlock, PomsBody, ProgressBar } from './styles'

const LoadingWrapper = styled.div`
  > div {
    top: 0;
  }
`

export const Content = styled.div`
  padding: 0.5rem 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const PomsList = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const Header = ({ balances, history, setShowFoodModal, gameData }) => {
  return (
    <div className={'layout-header'}>
      <Flex alignItems='center' style={{ gap: 8 }}>
        <PrevArrow width={24} onClick={() => history.push('/home')} />

        <BalanceBlock>
          <img src='/images/home/paw.png' alt='balance' />
          <div className={'balance'}>
            {balances?.loading ? <Skeleton width={50} height={18} minHeight='auto' /> : addCommasToNumber(+balances?.pom, 3)}
          </div>
        </BalanceBlock>
      </Flex>

      <BoneBlock onClick={() => setShowFoodModal(true)}>
        <img src='/images/home/bone.png' alt='bone' />
        <div className={'balance'}>x{gameData?.skeletonDog}</div>
      </BoneBlock>
    </div>
  )
}

let interval: any

const HomeGame = () => {
  const history = useHistory()
  const userData = checkUserData()
  const dispatch = useDispatch()
  const { fetchCharacters, fetchClaimReward, fetchCalculateProgress, fetchMissions, fetchFeeUpgrade } = useGameService()
  const { userBalance: balances } = useSelector((state: AppState) => state.wallet)
  const { dataModal } = useSelector((state: AppState) => state.modal.modalNotification)

  /**
   * State
   * ====================================================================
   */
  const [gameData, setGameData] = useState<{ loading: boolean; data: any }>({
    loading: true,
    data: null,
  })
  const [progressData, setProgressData] = useState<{ loading: boolean; data: any }>({
    loading: true,
    data: null,
  })
  const [missionList, setMissionList] = useState<{ loading: boolean; data: null | any[] }>({
    loading: true,
    data: null,
  })
  const [loading, setLoading] = useState(false)
  const [showFoodModal, setShowFoodModal] = useState(false)
  const [showLightningModal, setShowLightningModal] = useState(false)
  const [showRocketModal, setShowRocketModal] = useState(false)
  const [checkIsPC, setCheckIsPC] = useState(false)
  const [currentLevel, setCurrentLevel] = useState(0)

  /**
   * function
   * ====================================================================
   */

  const fetchGameData = useCallback(async () => {
    if (!userData?.address) return

    Promise.all([
      fetchCharacters({ keyword: userData?.address }, (res) => {
        setGameData({
          loading: false,
          data: res?.data?.data?.[0],
        })
      }),

      fetchMissions({ keyword: userData?.address }, (res) => {
        setMissionList({
          loading: false,
          data: res?.data?.data,
        })
      }),
    ])
  }, [fetchCharacters, fetchMissions, userData?.address])

  const isValidClaim = useCallback(() => {
    if (progressData?.data && !progressData?.data?.finishClaim) return true
    if (missionList?.data) {
      const countComplete = missionList?.data?.filter((x) => x?.isComplete)?.length
      const countClaimed = missionList?.data?.filter((x) => x?.isClaimGasFree)?.length
      if (countComplete > countClaimed) return true
    }
    if (+balances?.ton && +balances?.ton >= MIN_TON_FEE) return true

    return false
  }, [balances?.ton, missionList?.data, progressData?.data])

  const typeAvatar = useCallback(() => {
    if (!gameData?.data) return

    if (gameData?.data?.level >= 26) return 4
    if (gameData?.data?.level >= 21) return 3
    if (gameData?.data?.level >= 11) return 2

    return 1
  }, [gameData?.data])

  const progressPercent = useCallback(() => {
    if (!progressData?.data) return 0
    if (!progressData?.data?.startClaim) return 100

    const start = progressData?.data?.startClaim * 1000
    const end = progressData?.data?.finishClaim * 1000
    const now = Date.now()
    const rawProgress = ((now - start) / (end - start)) * 100
    return rawProgress <= 100 ? rawProgress : 100
  }, [progressData?.data])

  const handleClaim = async () => {
    if (!gameData?.data?.id || !missionList?.data || loading || progressPercent() < 100) return

    if (!isValidClaim()) {
      const countComplete = missionList?.data?.filter((x) => x?.isComplete)?.length
      if (countComplete < missionList?.data?.length) {
        dispatch(setModalNotification({ toggle: true, dataModal: { message: 'Complete missions to earn rewards' } }))
        return
      }

      if (+balances?.ton < MIN_TON_FEE) {
        dispatch(setModalNotification({ toggle: true, dataModal: { message: 'Min gas fee for claim 0.05TON' } }))
        return
      }

      return
    }

    setLoading(true)

    dispatch(
      setModalNotification({
        toggle: true,
        dataModal: {
          message: 'Claiming',
          buttonDisabled: true,
          loading: true,
        },
      })
    )

    const payload = {
      id: gameData?.data?.id,
      body: {
        mnemonic: encryptData(userData?.phrase),
      },
    }

    await fetchClaimReward(
      payload,
      async (res) => {
        setLoading(false)
        setGameData((prev) => ({
          ...prev,
          data: res?.data,
        }))
        dispatch(setModalNotification({ toggle: true, dataModal: { message: 'Claim success!!!' } }))
        await fetchCalculateProgress(gameData?.data?.id, (res) =>
          setProgressData({
            loading: false,
            data: res?.data,
          })
        )
      },
      () => {
        setLoading(false)
        dispatch(setModalNotification({ toggle: true, dataModal: { message: 'Claim failed' } }))
      }
    )
  }

  /**
   * Effect
   * ====================================================================
   */

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const height = window.innerHeight
      if (height < 580) setCheckIsPC(true)
    }
  }, [])

  useEffect(() => {
    fetchGameData()
  }, [fetchGameData])

  useEffect(() => {
    if (!gameData?.data?.id) return

    interval = setInterval(
      () =>
        fetchCalculateProgress(gameData?.data?.id, (res) => {
          setProgressData({
            loading: false,
            data: res?.data,
          })
        }),
      1000
    )

    return () => clearInterval(interval)
  }, [gameData?.data?.id, fetchCalculateProgress, progressData?.data?.finishClaim])

  useEffect(() => {
    if (!progressData?.data?.finishClaim || progressPercent() === 100) {
      clearInterval(interval)
    }
  }, [progressData, progressPercent])

  useEffect(() => {
    if (!gameData?.data?.id) return
    fetchFeeUpgrade(gameData?.data?.id, (res) => dispatch(setFeeGameUpgrade(res?.data)))
  }, [fetchFeeUpgrade, gameData?.data?.id, dispatch])

  useEffect(() => {
    const level = typeAvatar()
    if (!level) return

    setCurrentLevel(level)
  }, [typeAvatar])

  useEffect(() => {
    if (dataModal?.message && dataModal?.message !== 'Mission Completed!!!') return
    fetchGameData()
  }, [dataModal?.message, fetchGameData])

  return gameData?.loading || balances?.loading || progressData?.loading || missionList?.loading ? (
    <LoadingWrapper>
      <LoadingPage isSetTimeout={false} />
    </LoadingWrapper>
  ) : !gameData?.data ? (
    <Flex alignItems='center' justifyContent='center' flexDirection='column' mt={100}>
      <img src='/images/poms/poms-3.png' alt='bowl' width={200} />
      <Text as='p' color='#fff' textAlign='center' fontSize={32} fontFamily='Jua'>
        NOT FOUND
      </Text>
    </Flex>
  ) : (
    <>
      <Header history={history} balances={balances} setShowFoodModal={setShowFoodModal} gameData={gameData?.data} />
      <div className={'layout-content'}>
        <PrivateLayoutWithDock>
          <Content>
            <PomsList>
              <PomsBody>
                <Box
                  className={classNames('claim', {
                    active: isValidClaim() && progressPercent() === 100,
                  })}
                  onClick={handleClaim}
                >
                  <img src='/images/poms/heart.png' alt='claim' />
                  <span>Claim</span>
                </Box>
                <div className={'container'}>
                  <div className='pom'>
                    <img src='/images/poms/base.png' alt='base' className={classNames({ isPc: checkIsPC })} />

                    <img src={`/images/poms/${gameData?.data?.color}-${currentLevel}.png`} alt='avatar' />

                    {currentLevel && currentLevel > 1 && (
                      <Box
                        className={'arrow arrow-left'}
                        onClick={() => {
                          if (currentLevel > 1) setCurrentLevel((prev) => prev - 1)
                        }}
                      >
                        <PrevArrow />
                      </Box>
                    )}
                    {currentLevel && typeAvatar() && currentLevel < (typeAvatar() as number) && (
                      <Box
                        className={'arrow arrow-right'}
                        onClick={() =>
                          typeAvatar() && currentLevel < (typeAvatar() as number) && setCurrentLevel((prev) => prev + 1)
                        }
                      >
                        <NextArrow />
                      </Box>
                    )}
                  </div>
                </div>
              </PomsBody>

              {!progressData?.loading ? (
                <ProgressBar>
                  <div className='label'>
                    <CountDownCustom
                      remains={progressData?.data?.finishClaim * 1000}
                      render={{ hours: 'h', minutes: 'm', seconds: 's' }}
                    />
                    <div>{progressData?.loading ? '--' : addCommasToNumber(progressData?.data?.amount, 3)}</div>
                  </div>
                  <div className='bar'>
                    <div
                      className='progress-bar'
                      style={{
                        width: `${progressPercent()}%`,
                      }}
                    ></div>
                  </div>
                </ProgressBar>
              ) : (
                <Skeleton height={34} />
              )}
            </PomsList>
            <Actions>
              <button onClick={() => setShowRocketModal(true)}>
                <div>rockets</div>
                <div>{gameData?.data?.speedRocket} hours</div>
                <img src='/images/home/rocket.png' alt='rocket' className={'btn-icon'} />
                <GoldButton>Level Up</GoldButton>
              </button>
              <button onClick={() => setShowLightningModal(true)}>
                <div>lightning</div>
                <div>{gameData?.data?.speedLightning}/hour</div>
                <img src='/images/home/lightning.png' alt='lightning' className={'btn-icon'} />
                <GreenButton>Level Up</GreenButton>
              </button>
            </Actions>
          </Content>
          <LightningModalBody
            show={showLightningModal}
            onClose={() => setShowLightningModal(false)}
            gameData={gameData?.data}
            setGameData={setGameData}
          />
          <RocketModalBody
            show={showRocketModal}
            onClose={() => setShowRocketModal(false)}
            gameData={gameData?.data}
            setGameData={setGameData}
          />
        </PrivateLayoutWithDock>

        <FoodModalBody
          show={showFoodModal}
          onClose={() => setShowFoodModal(false)}
          gameData={gameData?.data}
          setGameData={setGameData}
        />
      </div>
    </>
  )
}

export default HomeGame
