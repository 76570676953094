import { useContext } from 'react'
import { ThemeContext as StyledThemeCopntext } from 'styled-components'
import { ThemeContext } from '../ThemeContext'

const useTheme = () => {
  const themeValues = useContext(ThemeContext)
  const theme = useContext(StyledThemeCopntext)
  return { theme, ...themeValues }
}

export default useTheme
