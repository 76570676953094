import axios from 'axios'
import { ROOT_API, ROOT_API_TIMEOUT } from 'constants/index'
import queryString from 'query-string'

const handle = axios.create({
  baseURL: ROOT_API,
  timeout: ROOT_API_TIMEOUT,
  headers: {
    'content-type': 'application/json',
  },
  paramsSerializer: (params) => queryString.stringify(params),
})



export const addToken = (token) => {
  handle.defaults.headers.common.Authorization = `Bearer ${token}`
}

export const removeToken = () => {
  delete handle.defaults.headers.common.Authorization
}

// UniFinance

export const getAffiliate = (address) => {
  try {
    return handle.get(`/referral-affiliate/${address}`)
  } catch (error) {
    const { response, message } = error
    if (response) {
      return response
    }
    return message
  }
}

export const getParentAffiliate = (address) => {
  try {
    return handle.get(`/referral-affiliate/parent/${address}`)
  } catch (error) {
    const { response, message } = error
    if (response) {
      return response
    }
    return message
  }
}

export const addAffiliate = (data) => {
  try {
    return handle.post('/referral-affiliate', data)
  } catch (error) {
    const { response, message } = error
    if (response) {
      return response
    }
    return message
  }
}

export const upgradeAffiliate = (data) => {
  try {
    return handle.post('/affiliate/upgrade-package', data)
  } catch (error) {
    const { response, message } = error
    if (response) {
      return response
    }
    return message
  }
}

export const getCommission = (address) => {
  try {
    return handle.get(`/commission/${address}`)
  } catch (error) {
    const { response, message } = error
    if (response) {
      return response
    }
    return message
  }
}

export const getSimpleCoinPrice = (data) => {
  const qs = queryString.stringify(data)
  try {
    return handle.get(`https://api.coingecko.com/api/v3/simple/price?${qs}`)
  } catch (error) {
    const { response, message } = error
    if (response) {
      return response
    }
    return message
  }
}

export default handle
