import { formatCode } from 'helpers/CommonHelper'
import { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { AppState } from 'state'
import styled from 'styled-components'
import { Skeleton } from 'widgets/Skeleton'
import GreenButton from './GreenButton'
import Modal from './Modal'
import QRModalBody from './QRModalBody'

const FriendsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  text-align: center;
  padding: 0.8rem;
  background: #ffe7a9;
  border-radius: 1.5rem;

  .title {
    color: #cc6606;
    font-family: Jua;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  .description {
    color: #8d4500;
    text-align: center;
    font-family: Jua;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .guide {
    color: #ff7c00;
    text-align: center;
    font-family: Jua;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 0.5rem;
  }
`

const FriendList = styled.div`
  margin-top: 1rem;
  max-height: 30vh;
  overflow-y: auto;

  .friend-item {
    padding: 0.5rem 0.8rem;
    border-radius: 1.5rem;
    display: flex;
    gap: 1rem;
    align-items: center;
    background: #fff2d1;

    color: #8d4500;
    font-family: Jua;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    &:not(:first-child) {
      margin-top: 0.5rem;
    }

    img {
      width: 60px;
      height: 60px;
    }
  }
`

const InviteButton = styled.div`
  font-size: 1.2rem;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -5rem;
`

interface FriendsProps {
  show: boolean
  onClose: () => void
}

const Friends: FC<FriendsProps> = ({ show, onClose }) => {
  const { myAffiliate } = useSelector((state: AppState) => state.user)
  const [inviterActive, setInviterActive] = useState(false)

  return (
    <>
      <Modal show={show} onClose={onClose}>
        <div className={'modal-header'}>My Friends</div>
        <FriendsWrapper>
          <div className='title'>
            Invited {!myAffiliate ? <Skeleton width={40} /> : myAffiliate?.children?.length || 0} Friend
          </div>
          <div className='description'>
            Get 20% bonus everytime your friend claims POMC. And 5% everytime his/her referrals claim it.
          </div>
          <a href='#' className='guide'>
            Full guide
          </a>
        </FriendsWrapper>
        <FriendList>
          {!myAffiliate ? (
            <Skeleton height={76} />
          ) : !myAffiliate?.children?.length ? (
            <></>
          ) : (
            myAffiliate?.children?.map((friend) => (
              <div key={friend?.id} className={'friend-item'}>
                <img src={`/images/avatar/avatar-${Math.floor(Math.random() * 4) + 1}.png`} alt={friend?.address} />
                <div>{formatCode(friend?.address, 6, 6)}</div>
              </div>
            ))
          )}
        </FriendList>
        <InviteButton>
          <GreenButton
            onClick={() => {
              setInviterActive((prev) => !prev)
              onClose()
            }}
          >
            Invite a friend
          </GreenButton>
        </InviteButton>
      </Modal>
      <QRModalBody show={inviterActive} onClose={() => setInviterActive((prev) => !prev)} />
    </>
  )
}

export default Friends
