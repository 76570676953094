import styled from 'styled-components'

const CountDown = () => {
  return (
    <Wrapper>
      <div className='overlay-background' />

      <div className='container'>
        <img src='/images/landing/countdown-img.png' alt='Countdown' />
        <div className='right-side'>
          <div>
            <div className='colorful-text'>
              <p>The war is about</p>
              <p>To begin</p>
            </div>

            <p className='description'>
              Your gateway to digital wealth and thrilling adventures. Manage your finances effortlessly with the Zeus88 Bot and
              dive into an exciting GameFi world where skills and luck can earn you real rewards.
            </p>
          </div>

          <div className='time-down'>
            <div className='coming-soon'>Launching Soon</div>
            <div className='time-list'>
              <div>
                <div>14</div>
                <p>Days</p>
              </div>
              <div>
                <div>08</div>
                <p>Hours</p>
              </div>
              <div>
                <div>42</div>
                <p>Minutes</p>
              </div>
              <div>
                <div>09</div>
                <p>Seconds</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default CountDown

const Wrapper = styled.div`
  width: 100%;
  aspect-ratio: 21 / 9;

  background: radial-gradient(47.86% 47.86% at 52.57% 50%, #1a0c00 0%, #010100 100%);
  position: relative;

  display: flex;
  align-items: center;

  > div {
    padding-top: 64px;
    padding-bottom: 64px;
    display: flex;
    align-items: center;
    gap: 64px;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      gap: 64px;
    }

    > img {
      width: 490px;
      aspect-ratio: 1;

      @media screen and (max-width: 1024px) {
        width: 320px;
      }

      @media screen and (max-width: 768px) {
        width: 490px;
      }

      @media screen and (max-width: 640px) {
        width: 100%;
      }
    }

    .right-side {
      display: flex;
      flex-direction: column;
      gap: 24px;

      @media screen and (max-width: 768px) {
        gap: 46px;
      }

      .colorful-text {
        font-family: Lora;
        font-size: 62px;
        font-weight: bold;
        line-height: 79.13px;
        text-align: left;

        background: linear-gradient(167.47deg, #fdfffd 33.85%, #fffa8e 65.47%, #fffb9f 72.42%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        word-spacing: -20px;

        @media screen and (max-width: 768px) {
          font-size: 48px;
          line-height: 61.15px;
        }
      }

      .description {
        font-family: Lora;
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        text-align: left;
        margin-top: 12px;
      }

      .time-down {
        display: flex;
        flex-direction: column;
        gap: 16px;

        @media screen and (max-width: 640px) {
          align-items: center;
        }

        .coming-soon {
          padding: 10px 24px 10px 24px;
          border-radius: 12px;
          border: 1px solid;
          background: #00000052;
          width: fit-content;
          border: none;
          border-top: 1px solid #ffffff52;
          font-size: 20px;
          font-weight: 500;
          line-height: 25.6px;
          text-align: left;
        }

        .time-list {
          display: flex;
          align-items: center;
          gap: 16px;

          > div {
            display: flex;
            flex-direction: column;
            gap: 8px;
            align-items: center;

            > div {
              border: 1px solid #ffffff70;
              border-radius: 12px;
              font-size: 36px;
              font-weight: 700;
              line-height: 45.95px;
              text-align: center;
              width: 66px;
              height: 66px;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            > p {
              font-size: 14px;
              font-weight: 400;
              line-height: 17.92px;
              text-align: center;
            }
          }
        }
      }
    }
  }

  border: 10px solid;
  border-image-slice: 1;
  border-width: 2px;
  border-image-source: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #edbd4b 24.5%, rgba(161, 71, 5, 0) 100%);

  .overlay-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('/images/landing/countdown-bg.png');
    background-size: 1763.87px 659px;
    background-position: center;
  }
`
