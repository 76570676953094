import { useEffect, useRef } from 'react'
import styled from 'styled-components'

const EARN_ITEMS = [
  {
    title: 'Tap To Earn',
    desc: 'The more you touch, the wealthier you become! Accumulate Zeus88 tokens to open cool weapon chests. Simple, fast, effective!',
    img: '/images/landing/earn-item1.png',
  },
  {
    title: 'Fight to Earn ( PVP, PVE)',
    desc: 'Battling is a core feature in Zeus88 that allows users to engage in strategic duels with other players and boss to earn $Zeus88, Reward',
    img: '/images/landing/earn-item2.png',
  },
  {
    title: 'Referrral program',
    desc: 'By inviting friends to join Zeus88, you can significantly enhance your gameplay experience and earning potential.',
    img: '/images/landing/earn-item3.png',
  },
]
const HowToEarn: React.FC = () => {
  const elementRefs = useRef<(HTMLDivElement | null)[]>([])

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('show')
          } 
        })
      },
      { rootMargin: '0px 0px -50% 0px' }
    )

    // Gắn observer vào từng phần tử
    elementRefs.current.forEach((el) => {
      if (el) observer.observe(el)
    })

   
  }, [])
  return (
    <Wrapper>
      <img className='badge' src='/images/landing/badge.png' alt='Badge' />

      <div className='container'>
        <div className='title'>
          <h1>How To Earn ?</h1>
          <p>
            Collect and train a diverse roster of legendary heroes, each with unique abilities and playstyles. Battle in a dynamic
            arena and earn blockchain-powered rewards.
          </p>
        </div>

        <div className='content'>
          <img src='/images/landing/zeus-sit-on-gold.png' alt='Zeus siting on gold' />
          <div className='right-earn'>
            <div className='line'>
              <img src='/images/landing/line.png' alt='' />
            </div>
            {EARN_ITEMS.map((item, index) => (
              <div className='item' key={index} ref={(el) => (elementRefs.current[index] = el)}>
                <div className='item__left'>
                  <img src={item.img} alt='' />
                </div>
                <div className='item__right'>
                  <div className='item-title'>{item.title}</div>
                  <div className='item-desc'>{item.desc}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default HowToEarn

const Wrapper = styled.div`
  background-image: url('/images/landing/how-earn-bg.webp');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-top: 82px;
  position: relative;

  border-top: 2px solid;
  border-image-slice: 1;
  border-width: 2px;
  border-image-source: linear-gradient(90deg, #000000 0%, #ffeb68 50%, #000000 100%);

  @media screen and (max-width: 640px) {
    padding-top: 64px;
  }

  .badge {
    position: absolute;
    width: 310px;
    top: -26px;
    left: 50%;
    transform: translateX(-50%);

    @media screen and (max-width: 640px) {
      width: 164px;
      top: -14px;
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    gap: 77px;

    @media screen and (max-width: 640px) {
      gap: 53px;
    }

    .title {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 12px;

      h1 {
        font-family: Lora;
        font-size: 62px;
        font-weight: 700;
        line-height: 79.13px;
        text-align: center;
        background: linear-gradient(167.47deg, #fdfffd 33.85%, #fffa8e 65.47%, #fffb9f 72.42%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        word-spacing: -20px;

        @media screen and (max-width: 640px) {
          font-size: 32px;
          line-height: 41px;
          word-spacing: -12px;
        }
      }

      p {
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        text-align: center;
        max-width: 539px;

        @media screen and (max-width: 640px) {
          font-size: 14px;
          line-height: 18px;
        }
      }
    }

    .content {
      display: grid;
      grid-template-columns: 1fr 1fr;

      @media screen and (max-width: 1024px) {
        display: flex;
        flex-direction: column-reverse;
        gap: 40px;
      }
    

      img {
        &:first-child {
          width: 673px;

          @media screen and (max-width: 640px) {
            width: 100%;
            transform: scale(1.2);
            transform-origin: bottom;
          }
        }
      }

      .right-earn {
      
        position: relative;
        .line {
          img {
            position: absolute;
            top: 0;
            left: -47%;
            object-fit: contain;
            height: 446px;
            @media (max-width: 1279px) {
              left: -45%;
              height: 360px;
            }
          }

          @media screen and (max-width: 1024px) {
            display: none;
          }
        }
        .item {
          position: absolute;
          display: flex;
          gap: 8px;
          opacity: 0;
          transition: opacity 0.6s ease-out;
          align-items: center;
          @media screen and (max-width: 1024px) {
            position: relative;
            top: 0;
            left: 0;
          }
          &:nth-child(2) {
            top: -4%;
            left: -14%;
            @media screen and (max-width: 1024px) {
              top: 0;
              left: 0;
              margin-bottom: 28px;
              @media screen and (min-width: 1536px) {
             left: -14%;
           }
            }
          }
          &:nth-child(3) {
            top: 24%;
            left: 4%;
            @media screen and (max-width: 1024px) {
              top: 0;
              left: 0;
              margin-bottom: 28px;
            }
            @media screen and (min-width: 1536px) {
             
              left: 0%;
            }
            
          }
          &:nth-child(4) {
            top: 56%;
            left: 5%;
           
            @media screen and (max-width: 1024px) {
              top: 0;
              left: 0;
            }
            @media screen and (min-width: 1536px) {
              left: -1%;
            }
           
          }

          &.show {
            opacity: 1;
          }
          &__left {
            img {
              min-width: 90px;
              max-width: 90px;
              height: 90px;
              @media screen and (max-width: 640px) {
                min-width: 50px;
                max-width: 50px;
                height: 50px;
              }
            }
          }
          &__right {
            display: flex;
            flex-direction: column;
            gap: 12px;

            .item-title {
              color: #fff;
              font-family: Lora;
              font-size: 24px;
              font-weight: 700;
              text-transform: capitalize;
              @media screen and (max-width: 640px) {
                font-size: 20px;
              }
            }

            .item-desc {
              color: #fff;
              font-family: Lora;
              font-size: 14px;
              font-weight: 400;
              @media screen and (max-width: 640px) {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
`
