import React, { ReactNode, useEffect, useRef } from 'react'
import styled from 'styled-components'

const Button = styled.div`
  width: 100px;
  margin: 0 auto;
  color: #fff;
  position: relative;
  white-space: nowrap;
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;

  > span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const GreenButton = ({
  children,
  onClick,
  width,
  className = '',
}: {
  children: ReactNode
  onClick?: () => void
  width?: number
  className?: string
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLSpanElement>(null)

  useEffect(() => {
    const wrapper = wrapperRef.current
    const content = contentRef.current
    if (content && wrapper) {
      wrapper.style.width = `${width ? width : content.offsetWidth + 40}px`
    }
  }, [width])

  return (
    <Button ref={wrapperRef} onClick={onClick} className={className}>
      <img src='/images/home/green-btn.png' alt='green-btn' />
      <span ref={contentRef}>{children}</span>
    </Button>
  )
}

export default GreenButton
