import { roundToUp } from 'round-to'
import momentTimezone from 'moment-timezone'

const isMobile = () => {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
  if (isMobile) {
    return true
  }
  return false
}

/**
 * @Dev
 * Func for remove empty entries of object
 */
const removeEmpty = (obj) =>
  Object.entries(obj)
    .map(([k, v]) => [k, v && typeof v === 'object' ? removeEmpty(v) : v])
    .reduce((a, [k, v]) => (v == null || v == undefined || v == '' ? a : ((a[k] = v), a)), {})

/**
 * @Dev
 * Func to round number
 */
export const MAX_NUMBER_VALUE = 1000000000
const MAX_STRING_NUMBER = 3
const abbreviateNumber2 = (num) => {
  let data = parseFloat(num / MAX_NUMBER_VALUE)
    .toFixed(2)
    .toString()
  const position = data.indexOf('.')
  data = data.substring(0, position + MAX_STRING_NUMBER)
  return data + 'B'
}
function roundNumber(n, scale = 2, abbNumber) {
  if (!n) return 0
  if (n >= MAX_NUMBER_VALUE && abbNumber) {
    return abbreviateNumber2(n)
  }
  const num = typeof +n !== 'number' ? 0 : parseFloat(n)
  if (!('' + num).includes('e')) {
    return +(Math.floor(num + 'e+' + scale) + 'e-' + scale)
  } else {
    var arr = ('' + num).split('e')
    var sig = ''
    if (+arr[1] + scale > 0) {
      sig = '+'
    }
    return +(Math.floor(+arr[0] + 'e' + sig + (+arr[1] + scale)) + 'e-' + scale)
  }
}

/**
 * @Dev
 * Use Intl for abbreviate number M K B T with round up and scale
 */
function abbreviateNumber(num, scale = 2) {
  // var i = Math.floor(Math.log(num) / Math.log(1000))
  // var fistCount = Math.floor(num / Math.pow(1000, i)).toString().length + scale + 1
  // console.log('iii', fistCount)

  var absNum = Math.abs(num)
  if (absNum < 0.001) return '0'
  if (absNum < 0.1 && absNum > 0.000001) {
    var addScale = absNum.toString().replace(/[1-9]/g, '').length - 2
    scale += addScale
  }
  return new Intl.NumberFormat('en-US', {
    maximumFractionDigits: scale,
    roundingIncrement: 1,
    notation: 'compact',
    compactDisplay: 'short',
  }).format(num)
}

/**
 * @Dev
 * Func for format number width replace
 */
function formatNumber(nb, scale = 2) {
  let n = roundToUp(parseFloat(nb), scale)

  let sign = +n < 0 ? '-' : ''
  let toStr = n.toString()
  if (!/e/i.test(toStr)) {
    return n
  }
  let [lead, decimal, pow] = n
    .toString()
    .replace(/^-/, '')
    .replace(/^([0-9]+)(e.*)/, '$1.$2')
    .split(/e|\./)
  return +pow < 0
    ? sign + '0.' + '0'.repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) + lead + decimal
    : sign +
        lead +
        (+pow >= decimal.length
          ? decimal + '0'.repeat(Math.max(+pow - decimal.length || 0, 0))
          : decimal.slice(0, +pow) + '.' + decimal.slice(+pow))
}

const formatDate = (date, format = 'HH:mm DD/MM/YYYY', country = 'Asia/Ho_Chi_Minh') => {
  // const country = JSON.parse(localStorage.getItem("userInfo"));
  if (date) {
    const tz = momentTimezone(date)
    const time = tz.tz(country).format(format)
    return time
  }
  return ''
}

const formatCode = (text, start, end, concat = '...') => {
  if (!text) return text
  const total = start + end
  const length = text.length
  if (total >= length) {
    return text
  }
  return [text.slice(0, start), text.slice(length - end)].join(concat)
}
const isValid = (value) => {
  if (value !== undefined && value !== null && !Number.isNaN(value)) {
    return true
  }
  return false
}
const maxArrayObj = (objects, field, isBigNumber) => {
  if (isBigNumber) {
    return objects.reduce((max, obj) => (max[field].toNumber() > obj[field].toNumber() ? max : obj))
  }
  return objects.reduce((max, obj) => (max[field] > obj[field] ? max : obj))
}

const getAllAddresses = (node) => {
  if (node) {
    let addresses = [node?.address]

    if (node?.children && node?.children?.length > 0) {
      for (const child of node.children) {
        addresses = addresses.concat(getAllAddresses(child))
      }
    }

    return addresses
  }

  return []
}

const isTonAddress = (address) => {
  if (typeof address !== 'string') return false
  if (address?.length !== 48 || (!address.startsWith('EQ') && !address.startsWith('UQ'))) return false
  return address
}

export {
  isValid,
  isMobile,
  removeEmpty,
  roundNumber,
  abbreviateNumber,
  formatNumber,
  formatDate,
  formatCode,
  maxArrayObj,
  getAllAddresses,
  isTonAddress,
}
