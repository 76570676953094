import styled from 'styled-components'

export const Actions = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 0.5rem 0;

  * {
    font-family: Jua;
  }

  button {
    position: relative;
    outline: none;
    border-radius: 2rem;
    padding: 0.5rem 1rem 2px;
    text-align: left;
    min-width: 150px;

    .btn-icon {
      height: 70px;
      position: absolute;
      right: -15px;
      top: -20px;
    }

    div:first-child {
      color: rgba(193, 90, 0, 0.62);

      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    div:nth-child(2) {
      color: #c15a00;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  button {
    span {
      font-size: 0.75rem;
    }
  }

  button:first-child {
    border: 2px solid #cc6606;
    background: linear-gradient(180deg, #ffe040 0%, #e86100 100%);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 16.5px 2px #ffc737 inset;
  }

  button:last-child {
    border: 2px solid #005b61;
    background: linear-gradient(180deg, #abff40 0%, #00bee8 100%);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 16.5px 2px #37ff87 inset;
  }
`

export const PomsBody = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  * {
    font-family: Jua;
  }

  .bowl,
  .claim {
    width: 50px;
    position: absolute;
    top: 20%;
    z-index: 2;
  }

  .bowl {
    left: 0;

    svg {
      position: absolute;
      bottom: -8px;
      right: 0;
    }
  }

  .claim {
    display: flex;
    justify-content: center;
    flex-direction: column;
    right: 0;
    color: #fff;
    text-shadow: 0px -2px 0px #57200b;
    font-family: Jua;
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    opacity: 0.5;

    span {
      margin-top: -1rem;
      color: #333;
    }

    &.active {
      --translateX: 0;
      --translateY: 0;
      animation: tada 2s linear infinite alternate;
      opacity: 1;

      span {
        color: #fff;
      }
    }
  }

  .container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    img[alt='base'] {
      position: absolute;
      bottom: -15%;
      z-index: -1;
    }

    .arrow {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      z-index: 2;

      &.arrow-left {
        left: -25px;
      }

      &.arrow-right {
        right: -25px;
      }
    }

    .pom {
      position: relative;
      z-index: 1;
      width: 60%;

      display: flex;
      align-items: center;
      justify-content: center;

      img {
        :nth-child(1) {
          width: 80%;
        }

        :nth-child(2) {
          width: 90%;
          position: relative;
          bottom: 15px;
        }
      }
    }
  }
`

export const ProgressBar = styled.div`
  .label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    * {
      font-family: Jua;
      font-size: 0.75rem;
    }
  }

  .bar {
    width: 100%;
    position: relative;
    height: 12px;
    border-radius: 16px;
    border: 1px solid rgba(102, 102, 102, 0.58);
    background: rgba(26, 30, 33, 0.74);
    box-shadow: 0px -7px 10px -8px rgba(255, 255, 255, 0.25) inset;

    .progress-bar {
      position: absolute;
      height: 100%;
      border-radius: 16px;
      background: linear-gradient(90deg, #a92052 0%, #f67c41 50%, #fff051 100%);
      box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.25) inset, 3px 1px 3px 0px rgba(0, 0, 0, 0.25);
    }
  }
`

export const BalanceBlock = styled.div`
  position: relative;
  margin-left: 25px;
  padding: 0.5rem 0.8rem 0.5rem 35px;
  border-radius: 1rem;
  background: rgba(0, 0, 0, 0.6);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #fff;
  font-family: Jua;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  img {
    width: auto;
    height: 50px;
    position: absolute;
    top: 50%;
    left: -25px;
    transform: translateY(-50%);
  }
`
export const BoneBlock = styled(BalanceBlock)`
  margin-left: 0;
  padding-left: 25px;

  img {
    position: absolute;
    top: 50%;
    left: -25px;
    transform: translateY(-50%);
  }
`
